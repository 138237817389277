// @flow
import React, { type Node } from 'react';
import styled from 'styled-components';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { MdOpenInNew } from 'react-icons/md';
import { Link as GatsbyLink } from 'gatsby';
import { color_primary } from '../utils/styles';
import type { Route } from '../utils/types';
import ccAxios from '../utils/ccAxios';

const StyledGatsbyLink = styled(GatsbyLink)`
  color: ${color_primary};

  &:hover,
  &:active {
    color: ${color_primary};
    text-decoration: underline ${color_primary} solid;
  }
`;

const HashAnchor = styled.a`
  color: ${color_primary};

  &:hover,
  &:active {
    color: ${color_primary};
    text-decoration: underline ${color_primary} solid;
  }
`;

const ExternalAnchor = styled(OutboundLink).attrs({
  rel: 'noopener noreferrer',
  target: '_blank',
})`
  color: ${color_primary};

  &:hover,
  &:active {
    color: ${color_primary};
    text-decoration: underline ${color_primary} solid;
  }
`;

// $FlowFixMe styled-components-v4
const PlatformAnchorContainer = styled.input.attrs({ type: 'button' })`
  color: ${color_primary};
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  &:hover,
  &:active {
    color: ${color_primary};
    text-decoration: underline ${color_primary} solid;
  }
`;

class PlatformAnchor extends React.PureComponent<{
  to: string,
  children: Node,
}> {
  render() {
    const { to, children, ...rest } = this.props;
    return (
      <PlatformAnchorContainer
        onClick={this.handleClick}
        value={children}
        {...rest}
      />
    );
  }

  handleClick = async () => {
    const parseFileName = (url) => {
      const split = url.split('/');
      return split[split.length - 1];
    };

    const { to } = this.props;
    const filename = parseFileName(to);
    let data;
    try {
      const response = await ccAxios.get(to);
      data = response.data;
      const blob = new Blob([data], { type: 'text/plain' });
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        // $FlowFixMe
        document.body.appendChild(elem);
        elem.click();
        // $FlowFixMe
        document.body.removeChild(elem);
        window.URL.revokeObjectURL(blob);
      }
    } catch (_) {}
  };
}

type Props = {
  children: Node,
  to: Route,
  hideExternalLink?: true,
  platform?: false,
};

export default class Link extends React.Component<Props> {
  render() {
    const { children, to, hideExternalLink, platform, ...rest } = this.props;
    if (platform) {
      return <PlatformAnchor to={to} {...rest} children={children} />;
    }
    if (to.startsWith('http')) {
      return (
        <ExternalAnchor href={to} {...rest}>
          {children}
          {!hideExternalLink && <MdOpenInNew />}
        </ExternalAnchor>
      );
    }
    if (to.startsWith('#')) {
      return (
        <HashAnchor href={to} {...rest}>
          {children}
        </HashAnchor>
      );
    }
    return <StyledGatsbyLink {...this.props} />;
  }
}
