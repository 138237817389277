// @flow
const routes = {
  aca_2018_skills_checklist: '/aca/2018-skills-checklist',
  aca_aspirant_skills_checklist: '/aca/aspirant-skills-checklist',
  aca_cl1_skills_checklist: '/aca/cl1-skills-checklist',
  aca_cl2_skills_checklist: '/aca/cl2-skills-checklist',
  aca_core_skills_checklist: '/aca/core-skills-checklist',
  blog_2012_04_28_my_story: '/blog/2012-04-28-my-story',
  blog_2014_08_01_kanarra_creek: '/blog/2014-08-01-kanarra-creek',
  blog_2014_09_20_rockfall_in_mystery_canyon:
    '/blog/2014-09-20-rockfall-in-mystery-canyon',
  blog_2015_10_23_egypt_2: '/blog/2015-10-23-egypt-2',
  blog_2016_05_14_neon_choprock: '/blog/2016-05-14-neon-choprock',
  blog_2016_09_16_hidden: '/blog/2016-09-16-hidden',
  blog_2016_09_18_bensen_creek: '/blog/2016-09-18-bensen-creek',
  blog_2016_09_25_dry_canyon_fall_colors:
    '/blog/2016-09-25-dry-canyon-fall-colors',
  blog_2016_10_14_aca_leaderguide_rendezvous_day_1:
    '/blog/2016-10-14-aca-leaderguide-rendezvous-day-1',
  blog_2016_10_15_aca_leaderguide_rendezvous_beau_beau:
    '/blog/2016-10-15-aca-leaderguide-rendezvous-beau-beau',
  blog_2016_10_15_aca_leaderguide_rendezvous_egypt_1:
    '/blog/2016-10-15-aca-leaderguide-rendezvous-egypt-1',
  blog_2017_06_08_fat_mans_misery: '/blog/2017-06-08-fat-mans-misery',
  blog_2017_06_09_lower_refrigerator: '/blog/2017-06-09-lower-refrigerator',
  blog_2017_06_10_heaps: '/blog/2017-06-10-heaps',
  blog_2017_09_30_dry_canyon_practice: '/blog/2017-09-30-dry-canyon-practice',
  blog_2017_11_11_williams_first_rappel:
    '/blog/2017-11-11-williams-first-rappel',
  blog_2017_12_05_canyons_no_longer_within_grand_staircase_escalante_national_monument:
    '/blog/2017-12-05-canyons-no-longer-within-grand-staircase-escalante-national-monument',
  blog_2018_03_02_monkey_business: '/blog/2018-03-02-monkey-business',
  blog_2018_03_03_no_kidding: '/blog/2018-03-03-no-kidding',
  blog_2018_03_03_sandthrax: '/blog/2018-03-03-sandthrax',
  blog_2018_03_18_about: '/blog/2018-03-18-about',
  blog_2018_03_24_gla_rendezvous: '/blog/2018-03-24-gla-rendezvous',
  blog_2018_04_15_one_hundred_canyons_later:
    '/blog/2018-04-15-one-hundred-canyons-later',
  blog_2018_04_19_halls_creek: '/blog/2018-04-19-halls-creek',
  blog_2018_05_19_robbers_roost: '/blog/2018-05-19-robbers-roost',
  blog_2018_09_10_subway: '/blog/2018-09-10-subway',
  blog_2018_09_20_mystery_revisited: '/blog/2018-09-20-mystery-revisited',
  blog_2018_09_21_engelstead: '/blog/2018-09-21-engelstead',
  blog_2018_09_22_rock_canyon: '/blog/2018-09-22-rock-canyon',
  blog_2018_10_26_spencer: '/blog/2018-10-26-spencer',
  blog_2018_11_09_moonflower_u_turn: '/blog/2018-11-09-moonflower-u-turn',
  blog_2018_11_10_tierdrop_not_tierdrop:
    '/blog/2018-11-10-tierdrop-not-tierdrop',
  blog_2018_11_11_lomatium: '/blog/2018-11-11-lomatium',
  blog_2019_03_16_north_wash: '/blog/2019-03-16-north-wash',
  blog_2019_05_18_rappel_maui: '/blog/2019-05-18-rappel-maui',
  blog_2019_06_15_rocky_mouth: '/blog/2019-06-15-rocky-mouth',
  blog_2019_06_25_worrier: '/blog/2019-06-25-worrier',
  blog_2019_06_30_every_mistake: '/blog/2019-06-30-every-mistake',
  blog_2020_02_23_the_2020_utah_canyoneers_shirt:
    '/blog/2020-02-23-the-2020-utah-canyoneers-shirt',
  blog_2020_04_26_aca_skills_checklist_updates:
    '/blog/2020-04-26-aca-skills-checklists-updates',
  blog_2020_07_12_lincolns_first_canyon:
    '/blog/2020-07-12-lincolns-first-canyon',
  blog_2020_07_24_crumbling_canyon: '/blog/2020-07-24-crumbling-canyon',
  blog_2020_09_07_super_amazing_ropewiki_map:
    '/blog/2020-09-07-super-amazing-ropewiki-map',
  blog_2020_09_27_great_white_icicle: '/blog/2020-09-27-great-white-icicle',
  blog_2021_07_05_adams_canyon: '/blog/2021-07-05-adams-canyon',
  blog_2021_08_06_south_fork_snoqualmie:
    '/blog/2021-08-06-south-fork-snoqualmie',
  blog_2021_08_07_hall_creek: '/blog/2021-08-07-hall-creek',
  blog_2021_08_08_change_creek: '/blog/2021-08-08-change-creek',
  // #
  glossary_1_technical_rating: '/glossary#1-technical-rating',
  glossary_2_ring_retrievable_rigging: '/glossary#2-ring-retrievable-rigging',
  glossary_2_technical_rating: '/glossary#2-technical-rating',
  glossary_3_technical_rating: '/glossary#3-technical-rating',
  glossary_3rd_class_scrambling: '/glossary#3rd-class-scrambling',
  glossary_4_technical_rating: '/glossary#4-technical-rating',
  glossary_4th_class_scrambling: '/glossary#4th-class-scrambling',
  // a
  glossary_a_water_rating: '/glossary#a-water-rating',
  glossary_abc: '/glossary#abc',
  glossary_abseil: '/glossary#abseil',
  glossary_aca: '/glossary#aca',
  glossary_aca_canyon_rating_system: '/glossary#aca-canyon-rating-system',
  glossary_accessory_cord: '/glossary#accessory-cord',
  glossary_ace: '/glossary#ace',
  glossary_aguanchor: '/glossary#aguanchor',
  glossary_aider: '/glossary#aider',
  glossary_american_canyoneering_association:
    '/glossary#american-canyoneering-association',
  glossary_american_death_triangle: '/glossary#american-death-triangle',
  glossary_anchor: '/glossary#anchor',
  glossary_angle: '/glossary#angle',
  glossary_ape_index: '/glossary#ape-index',
  glossary_approach: '/glossary#approach',
  glossary_arch: '/glossary#arch',
  glossary_armor: '/glossary#armor',
  glossary_art: '/glossary#art',
  glossary_ascend: '/glossary#ascend',
  glossary_ascender: '/glossary#ascender',
  glossary_assisted_rappel: '/glossary#assisted-rappel',
  glossary_association_for_canyoneering_education:
    '/glossary#association-for-canyoneering-education',
  glossary_atc: '/glossary#atc',
  glossary_ats: '/glossary#ats',
  glossary_atwood_gear: '/glossary#atwood-gear',
  glossary_autoblock: '/glossary#autoblock',
  // b
  glossary_b_water_rating: '/glossary#b-water-rating',
  glossary_backpack: '/glossary#backpack',
  glossary_bad_beta: '/glossary#bad-beta',
  glossary_bag_down_rigging_system: '/glossary#bag-down-rigging-system',
  glossary_bag_end: '/glossary#bag-end',
  glossary_bag_up_rigging_system: '/glossary#bag-up-rigging-system',
  glossary_basket: '/glossary#basket',
  glossary_basket_hitch: '/glossary#basket-hitch',
  glossary_batman_up: '/glossary#batman-up',
  glossary_beached_whale: '/glossary#beached-whale',
  glossary_belay: '/glossary#belay',
  glossary_belay_device: '/glossary#belay-device',
  glossary_belay_loop: '/glossary#belay-loop',
  glossary_belay_station: '/glossary#belay-station',
  glossary_belayer: '/glossary#belayer',
  glossary_bell: '/glossary#bell',
  glossary_bench: '/glossary#bench',
  glossary_bend: '/glossary#bend',
  glossary_best_man_at_risk: '/glossary#best-man-at-risk',
  glossary_best_person_at_risk: '/glossary#best-person-at-risk',
  glossary_beta: '/glossary#beta',
  glossary_beta_provider: '/glossary#beta-provider',
  glossary_bfr: '/glossary#bfr',
  glossary_bg: '/glossary#bg',
  glossary_bhk: '/glossary#bhk',
  glossary_big_friendly_rock: '/glossary#big-friendly-rock',
  glossary_bight: '/glossary#bight',
  glossary_biner: '/glossary#biner',
  glossary_biner_block: '/glossary#biner-block',
  glossary_biological_soil: '/glossary#biological-soil',
  glossary_bivouac: '/glossary#bivouac',
  glossary_bivy: '/glossary#bivy',
  glossary_bivy_sack: '/glossary#bivy-sack',
  glossary_blm: '/glossary#blm',
  glossary_block: '/glossary#block',
  glossary_block_hitch: '/glossary#block-hitch',
  glossary_blowout: '/glossary#blowout',
  glossary_bluff: '/glossary#bluff',
  glossary_bluugnome: '/glossary#bluugnome',
  glossary_bogley: '/glossary#bogley',
  glossary_bolt: '/glossary#bolt',
  glossary_bolt_kit: '/glossary#bolt-kit',
  glossary_bolt_wars: '/glossary#bolt-wars',
  glossary_bomb_bay: '/glossary#bomb-bay',
  glossary_bomber: '/glossary#bomber',
  glossary_booty: '/glossary#booty',
  glossary_bottom_belay: '/glossary#bottom-belay',
  glossary_boulder_hop: '/glossary#boulder-hop',
  glossary_box_canyon: '/glossary#box-canyon',
  glossary_bpar: '/glossary#bpar',
  glossary_brake_hand: '/glossary#brake-hand',
  glossary_bridging: '/glossary#bridging',
  glossary_buddy_check: '/glossary#buddy-check',
  glossary_bunny_strap: '/glossary#bunny-strap',
  glossary_bureau_of_land_management: '/glossary#bureau-of-land-management',
  glossary_bushwhack: '/glossary#bushwhack',
  glossary_butt_protector: '/glossary#butt-protector',
  glossary_butt_slide: '/glossary#butt-slide',
  glossary_butte: '/glossary#butte',
  // c
  glossary_c_water_rating: '/glossary#c-water-rating',
  glossary_cac: '/glossary#cac',
  glossary_cairn: '/glossary#cairn',
  glossary_cairn_anchor: '/glossary#cairn-anchor',
  glossary_cam: '/glossary#cam',
  glossary_candition: '/glossary#candition',
  glossary_canyon: '/glossary#canyon',
  glossary_canyon_collective: '/glossary#canyon-collective',
  glossary_canyon_left: '/glossary#canyon-left',
  glossary_canyon_mode: '/glossary#canyon-mode',
  glossary_canyon_quickdraw: '/glossary#canyon-quickdraw',
  glossary_canyon_resume: '/glossary#canyon-resume',
  glossary_canyon_right: '/glossary#canyon-right',
  glossary_canyon_skid_plate: '/glossary#canyon-skid-plate',
  glossary_canyoneer: '/glossary#canyoneer',
  glossary_canyoneering: '/glossary#canyoneering',
  glossary_canyoneering_circle: '/glossary#canyoneering-circle',
  glossary_canyoneering_usa: '/glossary#canyoneering-usa',
  glossary_canyoner: '/glossary#canyoner',
  glossary_canyoning: '/glossary#canyoning',
  glossary_canyonman: '/glossary#canyonman',
  glossary_canyonwerks: '/glossary#canyonwerks',
  glossary_capsize: '/glossary#capsize',
  glossary_carabiner: '/glossary#carabiner',
  glossary_care: '/glossary#care',
  glossary_catch_basin: '/glossary#catch-basin',
  glossary_ccc: '/glossary#ccc',
  glossary_ce_marking: '/glossary#ce-marking',
  glossary_cem: '/glossary#cem',
  glossary_cfs: '/glossary#cfs',
  glossary_cheater_stick: '/glossary#cheater-stick',
  glossary_check: '/glossary#check',
  glossary_chest_pouch: '/glossary#chest-pouch',
  glossary_chester: '/glossary#chester',
  glossary_chimney: '/glossary#chimney',
  glossary_chimneying: '/glossary#chimneying',
  glossary_chockstone: '/glossary#chockstone',
  glossary_chokestone: '/glossary#chokestone',
  glossary_choss: '/glossary#choss',
  glossary_chute: '/glossary#chute',
  glossary_cinch_wrap: '/glossary#cinch-wrap',
  glossary_civilian_conservation_corps: '/glossary#civilian-conservation-corps',
  glossary_class_3_scrambling: '/glossary#class-3-scrambling',
  glossary_class_4_scrambling: '/glossary#class-4-scrambling',
  glossary_climb_utah: '/glossary#climb-utah',
  glossary_climbing_harness: '/glossary#climbing-harness',
  glossary_clove_hitch: '/glossary#clove-hitch',
  glossary_coalition_of_american_canyoneers:
    '/glossary#coalition-of-american-canyoneers',
  glossary_colorado_plateau: '/glossary#colorado-plateau',
  glossary_compact_secure: '/glossary#compact-secure',
  glossary_constrictor_hitch: '/glossary#constrictor-hitch',
  glossary_contingency_8: '/glossary#contingency-8',
  glossary_contingency_block: '/glossary#contingency-block',
  glossary_contingency_redirect: '/glossary#contingency-redirect',
  glossary_contingency_rigging: '/glossary#contingency-rigging',
  glossary_contingency_system: '/glossary#contingency-system',
  glossary_contingency_totem: '/glossary#contingency-totem',
  glossary_contour_line: '/glossary#contour-line',
  glossary_cordelette: '/glossary#cordelette',
  glossary_cordura: '/glossary#cordura',
  glossary_core: '/glossary#core',
  glossary_core_shot: '/glossary#core-shot',
  glossary_counter_weight_rappel: '/glossary#counter-weight-rappel',
  glossary_courtesy_anchor: '/glossary#courtesy-anchor',
  glossary_courtesy_loop: '/glossary#courtesy-loop',
  glossary_courtesy_rigging: '/glossary#courtesy-rigging',
  glossary_courtesy_start: '/glossary#courtesy-start',
  glossary_cows_tail: '/glossary#cows-tail',
  glossary_creep: '/glossary#creep',
  glossary_critr: '/glossary#critr',
  glossary_cross_joint: '/glossary#cross-joint',
  glossary_cross_load: '/glossary#cross-load',
  glossary_crux: '/glossary#crux',
  glossary_cryptobiotic_soil: '/glossary#cryptobiotic-soil',
  glossary_cst: '/glossary#cst',
  glossary_cusa: '/glossary#cusa',
  glossary_cut_and_lower: '/glossary#cut-and-lower',
  // d
  glossary_daisy_chain: '/glossary#daisy-chain',
  glossary_deadman_anchor: '/glossary#deadman-anchor',
  glossary_dear: '/glossary#dear',
  glossary_descender: '/glossary#descender',
  glossary_descent_ring: '/glossary#descent-ring',
  glossary_desert_varnish: '/glossary#desert-varnish',
  glossary_deviation: '/glossary#deviation',
  glossary_dipstick: '/glossary#dipstick',
  glossary_dogbone: '/glossary#dogbone',
  glossary_double_rope_technique: '/glossary#double-rope-technique',
  glossary_double_strand: '/glossary#double-strand',
  glossary_down_canyon: '/glossary#down-canyon',
  glossary_down_climb: '/glossary#down-climb',
  glossary_drainage: '/glossary#drainage',
  glossary_draw: '/glossary#draw',
  glossary_dress: '/glossary#dress',
  glossary_drop_loop: '/glossary#drop-loop',
  glossary_drt: '/glossary#drt',
  glossary_dry_bag: '/glossary#dry-bag',
  glossary_dry_sack: '/glossary#dry-sack',
  glossary_dryfall: '/glossary#dryfall',
  glossary_ds: '/glossary#ds',
  glossary_dynamic_courtesy_rigging: '/glossary#dynamic-courtesy-rigging',
  glossary_dynamic_rope: '/glossary#dynamic-rope',
  glossary_dyneema: '/glossary#dyneema',
  // e
  glossary_earnest: '/glossary#earnest',
  glossary_edge_protection: '/glossary#edge-protection',
  glossary_edk: '/glossary#edk',
  glossary_egress: '/glossary#egress',
  glossary_elevator: '/glossary#elevator',
  glossary_elevatoring: '/glossary#elevatoring',
  glossary_elongation: '/glossary#elongation',
  glossary_emergency_medical_services: '/glossary#emergency-medical-services',
  glossary_ems: '/glossary#ems',
  glossary_entrada_sandstone: '/glossary#entrada-sandstone',
  glossary_epic: '/glossary#epic',
  glossary_equalette: '/glossary#equalette',
  glossary_equalized: '/glossary#equalized',
  glossary_etrier: '/glossary#etrier',
  glossary_euro_8: '/glossary#euro-8',
  glossary_european_death_knot: '/glossary#european-death-knot',
  glossary_exit: '/glossary#exit',
  glossary_expansion_bolt: '/glossary#expansion-bolt',
  glossary_exposure: '/glossary#exposure',
  // f
  glossary_fall_factor: '/glossary#fall-factor',
  glossary_fall_zone: '/glossary#fall-zone',
  glossary_farmer_john: '/glossary#farmer-john',
  glossary_fast_rope: '/glossary#fast-rope',
  glossary_fat_rope: '/glossary#fat-rope',
  glossary_fiddlestick: '/glossary#fiddlestick',
  glossary_figure_8_braid: '/glossary#figure-8-braid',
  glossary_figure_8_knot: '/glossary#figure-8-knot',
  glossary_figure_8_on_a_bight: '/glossary#figure-8-on-a-bight',
  glossary_figure_8_rappel_device: '/glossary#figure-8-rappel-device',
  glossary_figure_of_8_knot: '/glossary#figure-of-8-knot',
  glossary_fireman_belay: '/glossary#fireman-belay',
  glossary_flake: '/glossary#flake',
  glossary_flash: '/glossary#flash',
  glossary_flash_flood: '/glossary#flash-flood',
  glossary_flat_bend: '/glossary#flat-bend',
  glossary_flat_double_overhand_bend: '/glossary#flat-double-overhand-bend',
  glossary_flat_overhand_bend: '/glossary#flat-overhand-bend',
  glossary_flemish_bend: '/glossary#flemish-bend',
  glossary_floating_disconnect: '/glossary#floating-disconnect',
  glossary_flute: '/glossary#flute',
  glossary_focal_point: '/glossary#focal-point',
  glossary_focused_rigging: '/glossary#focused-rigging',
  glossary_foot_entrapment: '/glossary#foot-entrapment',
  glossary_foot_loop: '/glossary#foot-loop',
  glossary_free_hang: '/glossary#free-hang',
  glossary_free_rappel: '/glossary#free-rappel',
  glossary_friction_hitch: '/glossary#friction-hitch',
  glossary_frost_knot: '/glossary#frost-knot',
  glossary_full_wetsuit: '/glossary#full-wetsuit',
  // g
  glossary_g_pick: '/glossary#g-pick',
  glossary_galumph: '/glossary#galumph',
  glossary_gate: '/glossary#gate',
  glossary_gcnra: '/glossary#gcnra',
  glossary_gear_loop: '/glossary#gear-loop',
  glossary_geologists_pick: '/glossary#geologists-pick',
  glossary_ghost: '/glossary#ghost',
  glossary_girth_hitch: '/glossary#girth-hitch',
  glossary_glazing: '/glossary#glazing',
  glossary_global_positioning_system: '/glossary#global-positioning-system',
  glossary_gloves: '/glossary#gloves',
  glossary_glue_in: '/glossary#glue-in',
  glossary_gorging: '/glossary#gorging',
  glossary_gorp: '/glossary#gorp',
  glossary_gps: '/glossary#gps',
  glossary_grappling_hook: '/glossary#grappling-hook',
  glossary_gsenm: '/glossary#gsenm',
  glossary_guide_hand: '/glossary#guide-hand',
  glossary_guide_line: '/glossary#guide-line',
  glossary_guided_rappel: '/glossary#guided-rappel',
  glossary_guitar_hero: '/glossary#guitar-hero',
  // h
  glossary_hallway: '/glossary#hallway',
  glossary_hand_line: '/glossary#hand-line',
  glossary_hands_free_backup: '/glossary#hands-free-backup',
  glossary_hanger: '/glossary#hanger',
  glossary_happy_hooker: '/glossary#happy-hooker',
  glossary_hard_lock_off: '/glossary#hard-lock-off',
  glossary_hard_start: '/glossary#hard-start',
  glossary_harness: '/glossary#harness',
  glossary_harness_interruptus: '/glossary#harness-interruptus',
  glossary_haul_system: '/glossary#haul-system',
  glossary_hcv: '/glossary#hcv',
  glossary_helmet: '/glossary#helmet',
  glossary_helmet_police: '/glossary#helmet-police',
  glossary_helmet_stand: '/glossary#helmet-stand',
  glossary_high_stemming: '/glossary#high-stemming',
  glossary_hitch: '/glossary#hitch',
  glossary_hitrr: '/glossary#hitrr',
  glossary_hms_carabiner: '/glossary#hms-carabiner',
  glossary_hoodoo: '/glossary#hoodoo',
  glossary_hoodoo_rock_formation: '/glossary#hoodoo-rock-formation',
  glossary_hook: '/glossary#hook',
  glossary_hooking: '/glossary#hooking',
  glossary_hueco: '/glossary#hueco',
  glossary_human_anchor: '/glossary#human-anchor',
  glossary_human_pyramid: '/glossary#human-pyramid',
  // i
  glossary_i_beam_carabiner: '/glossary#i-beam-carabiner',
  glossary_i_time_rating: '/glossary#i-time-rating',
  glossary_ibis_hook: '/glossary#ibis-hook',
  glossary_icopro: '/glossary#icopro',
  glossary_ii_time_rating: '/glossary#ii-time-rating',
  glossary_iii_time_rating: '/glossary#iii-time-rating',
  glossary_imlay_canyon_gear: '/glossary#imlay-canyon-gear',
  glossary_inreach: '/glossary#inreach',
  glossary_italian_hitch: '/glossary#italian-hitch',
  glossary_iv_time_rating: '/glossary#iv-time-rating',
  // j
  glossary_jester: '/glossary#jester',
  glossary_joint: '/glossary#joint',
  glossary_joker: '/glossary#joker',
  glossary_jug: '/glossary#jug',
  glossary_jumar: '/glossary#jumar',
  // k
  glossary_kayenta_sandstone: '/glossary#kayenta-sandstone',
  glossary_keeper_pothole: '/glossary#keeper-pothole',
  glossary_keg: '/glossary#keg',
  glossary_kelsey_exit: '/glossary#kelsey-exit',
  glossary_kernmantle_rope: '/glossary#kernmantle-rope',
  glossary_kilonewton: '/glossary#kilonewton',
  glossary_kn: '/glossary#kn',
  glossary_knot: '/glossary#knot',
  glossary_knot_block: '/glossary#knot-block',
  glossary_knot_chock: '/glossary#knot-chock',
  // l
  glossary_lamar: '/glossary#lamar',
  glossary_lapar: '/glossary#lapar',
  glossary_last_person_at_risk: '/glossary#last-person-at-risk',
  glossary_lcv: '/glossary#lcv',
  glossary_ldc: '/glossary#ldc',
  glossary_leave_no_trace: '/glossary#leave-no-trace',
  glossary_left_down_canyon: '/glossary#left-down-canyon',
  glossary_left_up_canyon: '/glossary#left-up-canyon',
  glossary_leg_loop: '/glossary#leg-loop',
  glossary_leg_wrap: '/glossary#leg-wrap',
  glossary_leo: '/glossary#leo',
  glossary_lift_and_shift: '/glossary#lift-and-shift',
  glossary_lmar: '/glossary#lmar',
  glossary_lmr: '/glossary#lmr',
  glossary_lnt: '/glossary#lnt',
  glossary_load_releasing_hitch: '/glossary#load-releasing-hitch',
  glossary_loaded_for_bear: '/glossary#loaded-for-bear',
  glossary_log_soup: '/glossary#log-soup',
  glossary_logjam: '/glossary#logjam',
  glossary_long_rappel: '/glossary#long-rappel',
  glossary_loop: '/glossary#loop',
  glossary_lower: '/glossary#lower',
  glossary_lpar: '/glossary#lpar',
  glossary_luc: '/glossary#luc',
  // m
  glossary_ma: '/glossary#ma',
  glossary_macrame: '/glossary#macrame',
  glossary_mae_west_slot: '/glossary#mae-west-slot',
  glossary_magic_x: '/glossary#magic-x',
  glossary_major_axis: '/glossary#major-axis',
  glossary_marginal_anchor: '/glossary#marginal-anchor',
  glossary_mariners_hitch: '/glossary#mariners-hitch',
  glossary_master_point: '/glossary#master-point',
  glossary_maxxipad: '/glossary#maxxipad',
  glossary_mbs: '/glossary#mbs',
  glossary_meat_anchor: '/glossary#meat-anchor',
  glossary_mechanical_advantage: '/glossary#mechanical-advantage',
  glossary_mia_exit: '/glossary#mia-exit',
  glossary_micro_traxion: '/glossary#micro-traxion',
  glossary_minimum_breaking_strength: '/glossary#minimum-breaking-strength',
  glossary_minor_axis: '/glossary#minor-axis',
  glossary_mmo: '/glossary#mmo',
  glossary_moki: '/glossary#moki',
  glossary_monsoon_season: '/glossary#monsoon-season',
  glossary_monsoonal_rain: '/glossary#monsoonal-rain',
  glossary_moqui: '/glossary#moqui',
  glossary_moqui_marble: '/glossary#moqui-marble',
  glossary_moqui_steps: '/glossary#moqui-steps',
  glossary_mountain: '/glossary#mountain',
  glossary_mule_hitch: '/glossary#mule-hitch',
  glossary_multi_directional_load: '/glossary#multi-directional-load',
  glossary_multi_pitch_rappel: '/glossary#multi-pitch-rappel',
  glossary_multi_point_anchor: '/glossary#multi-point-anchor',
  glossary_multi_stage_rappel: '/glossary#multi-stage-rappel',
  glossary_munter_hitch: '/glossary#munter-hitch',
  glossary_munter_mule_overhand: '/glossary#munter-mule-overhand',
  // n
  glossary_narrows: '/glossary#narrows',
  glossary_national_park_service: '/glossary#national-park-service',
  glossary_natural_anchor: '/glossary#natural-anchor',
  glossary_natural_bridge: '/glossary#natural-bridge',
  glossary_navajo_sandstone: '/glossary#navajo-sandstone',
  glossary_neo: '/glossary#neo',
  glossary_neoprene: '/glossary#neoprene',
  glossary_no_extension: '/glossary#no-extension',
  glossary_nose_carabiner: '/glossary#nose-carabiner',
  glossary_nose_landform: '/glossary#nose-landform',
  glossary_nps: '/glossary#nps',
  glossary_nuisance_rappel: '/glossary#nuisance-rappel',
  glossary_nylon: '/glossary#nylon',
  // o
  glossary_off_width: '/glossary#off-width',
  glossary_on_rope_canyoneering: '/glossary#on-rope-canyoneering',
  glossary_open_end_prusik: '/glossary#open-end-prusik',
  glossary_orv: '/glossary#orv',
  glossary_overhand_knot: '/glossary#overhand-knot',
  glossary_overhand_on_a_bight: '/glossary#overhand-on-a-bight',
  glossary_overhang: '/glossary#overhang',
  glossary_ow: '/glossary#ow',
  // p
  glossary_pack_drag: '/glossary#pack-drag',
  glossary_pack_rope: '/glossary#pack-rope',
  glossary_pack_toss: '/glossary#pack-toss',
  glossary_packraft: '/glossary#packraft',
  glossary_partner_assist: '/glossary#partner-assist',
  glossary_partner_capture: '/glossary#partner-capture',
  glossary_pas: '/glossary#pas',
  glossary_pass_a_knot: '/glossary#pass-a-knot',
  glossary_pcd: '/glossary#pcd',
  glossary_peak: '/glossary#peak',
  glossary_personal_locator_beacon: '/glossary#personal-locator-beacon',
  glossary_petroglyph: '/glossary#petroglyph',
  glossary_pick_off: '/glossary#pick-off',
  glossary_picket: '/glossary#picket',
  glossary_picpocket: '/glossary#picpocket',
  glossary_pictograph: '/glossary#pictograph',
  glossary_pig_rig: '/glossary#pig-rig',
  glossary_piggyback: '/glossary#piggyback',
  glossary_piton: '/glossary#piton',
  glossary_plaquette: '/glossary#plaquette',
  glossary_plb: '/glossary#plb',
  glossary_pmp: '/glossary#pmp',
  glossary_pnw: '/glossary#pnw',
  glossary_poach: '/glossary#poach',
  glossary_polyester: '/glossary#polyester',
  glossary_polypropylene: '/glossary#polypropylene',
  glossary_pot_sticker: '/glossary#pot-sticker',
  glossary_potable: '/glossary#potable',
  glossary_pothole: '/glossary#pothole',
  glossary_pothole_escape_technique: '/glossary#pothole-escape-technique',
  glossary_potshot: '/glossary#potshot',
  glossary_progress_capture_device: '/glossary#progress-capture-device',
  glossary_prusik: '/glossary#prusik',
  glossary_prusik_minding_pulley: '/glossary#prusik-minding-pulley',
  glossary_pull: '/glossary#pull',
  glossary_pull_cord: '/glossary#pull-cord',
  glossary_pull_side: '/glossary#pull-side',
  glossary_pulley: '/glossary#pulley',
  glossary_purcell_prusik: '/glossary#purcell-prusik',
  // q
  glossary_quad: '/glossary#quad',
  glossary_quick_link: '/glossary#quick-link',
  glossary_quickdraw: '/glossary#quickdraw',
  glossary_quicksand: '/glossary#quicksand',
  // r
  glossary_r_risk_rating: '/glossary#r-risk-rating',
  glossary_r_section: '/glossary#r-section',
  glossary_ralston_rock: '/glossary#ralston-rock',
  glossary_ralstone: '/glossary#ralstone',
  glossary_rap: '/glossary#rap',
  glossary_rapid_link: '/glossary#rapid-link',
  glossary_rapide: '/glossary#rapide',
  glossary_rappel: '/glossary#rappel',
  glossary_rappel_deviation: '/glossary#rappel-deviation',
  glossary_rappel_device: '/glossary#rappel-device',
  glossary_rappel_end: '/glossary#rappel-end',
  glossary_rappel_line: '/glossary#rappel-line',
  glossary_rappel_redirect: '/glossary#rappel-redirect',
  glossary_rappel_ring: '/glossary#rappel-ring',
  glossary_rappel_side: '/glossary#rappel-side',
  glossary_rappeller: '/glossary#rappeller',
  glossary_rappelling_harness: '/glossary#rappelling-harness',
  glossary_ratchet: '/glossary#ratchet',
  glossary_rdc: '/glossary#rdc',
  glossary_rebelay: '/glossary#rebelay',
  glossary_recirculating_current: '/glossary#recirculating-current',
  glossary_redirect: '/glossary#redirect',
  glossary_redundant: '/glossary#redundant',
  glossary_redundant_wrap: '/glossary#redundant-wrap',
  glossary_releasable_rigging_system: '/glossary#releasable-rigging-system',
  glossary_releasable_twin_rope_system: '/glossary#releasable-twin-rope-system',
  glossary_release_cord: '/glossary#release-cord',
  glossary_rendezvous: '/glossary#rendezvous',
  glossary_rescue_rope: '/glossary#rescue-rope',
  glossary_restop: '/glossary#restop',
  glossary_rethreaded_figure_8_bend: '/glossary#rethreaded-figure-8-bend',
  glossary_retrievable_rigging_system: '/glossary#retrievable-rigging-system',
  glossary_ridge: '/glossary#ridge',
  glossary_rigging: '/glossary#rigging',
  glossary_right_down_canyon: '/glossary#right-down-canyon',
  glossary_right_up_canyon: '/glossary#right-up-canyon',
  glossary_ring_bend: '/glossary#ring-bend',
  glossary_risk_rating: '/glossary#risk-rating',
  glossary_road_trip_ryan: '/glossary#road-trip-ryan',
  glossary_rock_formation: '/glossary#rock-formation',
  glossary_roll: '/glossary#roll',
  glossary_rope: '/glossary#rope',
  glossary_rope_bag: '/glossary#rope-bag',
  glossary_rope_entanglement: '/glossary#rope-entanglement',
  glossary_rope_grab: '/glossary#rope-grab',
  glossary_rope_groove: '/glossary#rope-groove',
  glossary_rope_management: '/glossary#rope-management',
  glossary_rope_spool: '/glossary#rope-spool',
  glossary_ropewiki: '/glossary#ropewiki',
  glossary_ruc: '/glossary#ruc',
  glossary_runner: '/glossary#runner',
  glossary_runner_end: '/glossary#runner-end',
  // s
  glossary_saddle: '/glossary#saddle',
  glossary_safety_check: '/glossary#safety-check',
  glossary_safety_line: '/glossary#safety-line',
  glossary_safety_rigging: '/glossary#safety-rigging',
  glossary_safety_tether: '/glossary#safety-tether',
  glossary_sand_bag: '/glossary#sand-bag',
  glossary_sand_trap: '/glossary#sand-trap',
  glossary_sand_trap_anchor: '/glossary#sand-trap-anchor',
  glossary_sandstone: '/glossary#sandstone',
  glossary_sandtrap: '/glossary#sandtrap',
  glossary_sar: '/glossary#sar',
  glossary_scrambling: '/glossary#scrambling',
  glossary_scree: '/glossary#scree',
  glossary_scuttlebutt: '/glossary#scuttlebutt',
  glossary_search_and_rescue: '/glossary#search-and-rescue',
  glossary_secret_canyon: '/glossary#secret-canyon',
  glossary_self_belay: '/glossary#self-belay',
  glossary_self_minding: '/glossary#self-minding',
  glossary_self_rescue: '/glossary#self-rescue',
  glossary_semi_keeper_pothole: '/glossary#semi-keeper-pothole',
  glossary_sequence_rappels: '/glossary#sequence-rappels',
  glossary_sequencing: '/glossary#sequencing',
  glossary_serene: '/glossary#serene',
  glossary_set_rope_length: '/glossary#set-rope-length',
  glossary_sewn_prusik_loop: '/glossary#sewn-prusik-loop',
  glossary_sheath: '/glossary#sheath',
  glossary_sheath_slippage: '/glossary#sheath-slippage',
  glossary_shelf: '/glossary#shelf',
  glossary_short_rappel: '/glossary#short-rappel',
  glossary_short_rope: '/glossary#short-rope',
  glossary_shorty_wetsuit: '/glossary#shorty-wetsuit',
  glossary_shuffle_butt: '/glossary#shuffle-butt',
  glossary_side_drainage: '/glossary#side-drainage',
  glossary_silo: '/glossary#silo',
  glossary_simul_rap: '/glossary#simul-rap',
  glossary_simultaneous_rappel: '/glossary#simultaneous-rappel',
  glossary_single_rope_technique: '/glossary#single-rope-technique',
  glossary_single_strand: '/glossary#single-strand',
  glossary_sinking_sand: '/glossary#sinking-sand',
  glossary_siphon: '/glossary#siphon',
  glossary_sit_start: '/glossary#sit-start',
  glossary_sitla: '/glossary#sitla',
  glossary_skinny_end: '/glossary#skinny-end',
  glossary_skinny_rope: '/glossary#skinny-rope',
  glossary_slickrock: '/glossary#slickrock',
  glossary_slide: '/glossary#slide',
  glossary_sliding_x: '/glossary#sliding-x',
  glossary_sling: '/glossary#sling',
  glossary_slog: '/glossary#slog',
  glossary_slot_canyon: '/glossary#slot-canyon',
  glossary_slot_designation: '/glossary#slot-designation',
  glossary_slow_rope: '/glossary#slow-rope',
  glossary_smear: '/glossary#smear',
  glossary_smooth_operator: '/glossary#smooth-operator',
  glossary_social_trail: '/glossary#social-trail',
  glossary_soft_lock_off: '/glossary#soft-lock-off',
  glossary_soft_rope_grab: '/glossary#soft-rope-grab',
  glossary_soft_start: '/glossary#soft-start',
  glossary_solid: '/glossary#solid',
  glossary_solo: '/glossary#solo',
  glossary_solution_pocket: '/glossary#solution-pocket',
  glossary_spectra: '/glossary#spectra',
  glossary_spelegyca: '/glossary#spelegyca',
  glossary_spine: '/glossary#spine',
  glossary_spot: '/glossary#spot',
  glossary_spot_plb: '/glossary#spot-plb',
  glossary_spring_loaded_camming_device:
    '/glossary#spring-loaded-camming-device',
  glossary_spur: '/glossary#spur',
  glossary_sqwurel: '/glossary#sqwurel',
  glossary_srene: '/glossary#srene',
  glossary_srt: '/glossary#srt',
  glossary_stacked_overhand_bend: '/glossary#stacked-overhand-bend',
  glossary_star_bridging: '/glossary#star-bridging',
  glossary_static_block: '/glossary#static-block',
  glossary_static_block_to_lower: '/glossary#static-block-to-lower',
  glossary_static_courtesy_rigging: '/glossary#static-courtesy-rigging',
  glossary_static_rope: '/glossary#static-rope',
  glossary_stein_knot: '/glossary#stein-knot',
  glossary_stemming: '/glossary#stemming',
  glossary_step: '/glossary#step',
  glossary_stone_8: '/glossary#stone-8',
  glossary_stone_knot: '/glossary#stone-knot',
  glossary_stone_totem: '/glossary#stone-totem',
  glossary_strainer: '/glossary#strainer',
  glossary_subterranean_deadman: '/glossary#subterranean-deadman',
  glossary_subway: '/glossary#subway',
  glossary_super_amazing_canyoneering_map:
    '/glossary#super-amazing-canyoneering-map',
  glossary_surface_deadman: '/glossary#surface-deadman',
  glossary_suwa: '/glossary#suwa',
  glossary_swift_water: '/glossary#swift-water',
  // t
  glossary_taco: '/glossary#taco',
  glossary_tafoni: '/glossary#tafoni',
  glossary_tag_line: '/glossary#tag-line',
  glossary_tail: '/glossary#tail',
  glossary_tail_down_rigging_system: '/glossary#tail-down-rigging-system',
  glossary_tail_up_retrievable_rigging_system:
    '/glossary#tail-up-retrievable-rigging-system',
  glossary_tail_up_rigging_system: '/glossary#tail-up-rigging-system',
  glossary_talon: '/glossary#talon',
  glossary_talus: '/glossary#talus',
  glossary_tandem_rappel: '/glossary#tandem-rappel',
  glossary_tape: '/glossary#tape',
  glossary_technical_ledge: '/glossary#technical-ledge',
  glossary_technical_rating: '/glossary#technical-rating',
  glossary_technical_section: '/glossary#technical-section',
  glossary_technora: '/glossary#technora',
  glossary_tensile_strength: '/glossary#tensile-strength',
  glossary_tensionless_rigging: '/glossary#tensionless-rigging',
  glossary_tg: '/glossary#tg',
  glossary_throw_n_go: '/glossary#throw-n-go',
  glossary_tibloc: '/glossary#tibloc',
  glossary_time_rating: '/glossary#time-rating',
  glossary_timely: '/glossary#timely',
  glossary_tng: '/glossary#tng',
  glossary_toggle: '/glossary#toggle',
  glossary_toothed_ascender: '/glossary#toothed-ascender',
  glossary_top_rope_belay: '/glossary#top-rope-belay',
  glossary_topo: '/glossary#topo',
  glossary_topographic_map: '/glossary#topographic-map',
  glossary_toss_n_go: '/glossary#toss-n-go',
  glossary_tostada: '/glossary#tostada',
  glossary_totem: '/glossary#totem',
  glossary_track_line: '/glossary#track-line',
  glossary_trade_canyon: '/glossary#trade-canyon',
  glossary_transient_anchor: '/glossary#transient-anchor',
  glossary_trash_compactor: '/glossary#trash-compactor',
  glossary_traveling_rope_grab: '/glossary#traveling-rope-grab',
  glossary_traverse: '/glossary#traverse',
  glossary_traverse_line: '/glossary#traverse-line',
  glossary_triaxial_load: '/glossary#triaxial-load',
  glossary_triple_clove_hitch: '/glossary#triple-clove-hitch',
  glossary_truck: '/glossary#truck',
  glossary_tubular_webbing: '/glossary#tubular-webbing',
  glossary_twin_rope_system: '/glossary#twin-rope-system',
  glossary_tyrolean_traverse: '/glossary#tyrolean-traverse',
  // u
  glossary_uiaa: '/glossary#uiaa',
  glossary_unbetaed_canyon: '/glossary#unbetaed-canyon',
  glossary_undercut: '/glossary#undercut',
  glossary_up_canyon: '/glossary#up-canyon',
  glossary_usgs: '/glossary#usgs',
  glossary_usgs_topographic_maps: '/glossary#usgs-topographic-maps',
  glossary_utility_cord: '/glossary#utility-cord',
  // v
  glossary_v7_academy: '/glossary#v7-academy',
  glossary_v_time_rating: '/glossary#v-time-rating',
  glossary_valdotain_tresse: '/glossary#valdotain-tresse',
  glossary_vertaco: '/glossary#vertaco',
  glossary_vi_time_rating: '/glossary#vi-time-rating',
  glossary_via_ferrata: '/glossary#via-ferrata',
  glossary_vrg: '/glossary#vrg',
  glossary_vt: '/glossary#vt',
  glossary_vt_prusik: '/glossary#vt-prusik',
  // w
  glossary_wade: '/glossary#wade',
  glossary_wag_bag: '/glossary#wag-bag',
  glossary_waist_belt: '/glossary#waist-belt',
  glossary_wanchor: '/glossary#wanchor',
  glossary_water_anchor: '/glossary#water-anchor',
  glossary_water_hazard: '/glossary#water-hazard',
  glossary_water_knot: '/glossary#water-knot',
  glossary_water_pocket: '/glossary#water-pocket',
  glossary_water_rating: '/glossary#water-rating',
  glossary_waterpocket: '/glossary#waterpocket',
  glossary_watershed: '/glossary#watershed',
  glossary_wccm: '/glossary#wccm',
  glossary_webbing: '/glossary#webbing',
  glossary_wetsuit: '/glossary#wetsuit',
  glossary_whiptail: '/glossary#whiptail',
  glossary_wide_end: '/glossary#wide-end',
  glossary_wingate_sandstone: '/glossary#wingate-sandstone',
  glossary_working_end_carabiner: '/glossary#working-end-carabiner',
  glossary_working_end_rope: '/glossary#working-end-rope',
  glossary_worm: '/glossary#worm',
  glossary_wrap_3_pull_2: '/glossary#wrap-3-pull-2',
  // x
  glossary_x_canyon: '/glossary#x-canyon',
  glossary_x_risk_rating: '/glossary#x-risk-rating',
  glossary_x_section: '/glossary#x-section',
  glossary_xx_risk_rating: '/glossary#xx-risk-rating',
  // y
  glossary_yahoo_canyons_group: '/glossary#yahoo-canyons-group',
  glossary_yds: '/glossary#yds',
  glossary_yosemite_decimal_system: '/glossary#yosemite-decimal-system',
  // z
  glossary_z_rig: '/glossary#z-rig',
  glossary_zac: '/glossary#zac',
  glossary_zion_trifecta: '/glossary#zion-trifecta',
  glossary_zions: '/glossary#zions',
  glossary_zip: '/glossary#zip',
  glossary_znp: '/glossary#znp',
  // Pages
  page_404: '/404',
  page_about: '/about',
  page_aca: '/aca',
  page_anchors: '/anchors',
  page_blog: '/blog',
  page_canyon_rating_system: '/canyon-rating-system',
  page_canyoneering: '/canyoneering',
  page_carabiner: '/carabiner',
  page_check: '/check',
  page_dear: '/dear',
  page_earnest: '/earnest',
  page_glossary: '/glossary',
  page_other_canyon_rating_systems: '/other-canyon-rating-systems',
  page_partner_capture: '/partner-capture',
  page_pronunciation: '/pronunciation',
  page_pull: '/pull',
  page_quizzes: '/quizzes',
  page_root: '/',
  page_rope: '/rope',
  page_single_solid_natural_anchors: '/single-solid-natural-anchors',
  page_step: '/step',
  page_water_bottle: '/water-bottle',
};

export default routes;
