export const IS_DEVELOP = process.env.CANYON_COMPANION_DEVELOP === 'true';
export const PLATFORM_URL = IS_DEVELOP
  ? 'http://localhost:3000'
  : 'https://canyon-companion.herokuapp.com';

export const IMAGE_DIRECTORY = 'https://canyoncompanion.com/opengraph/';
export const DEFAULT_IMAGE = `${IMAGE_DIRECTORY}lep.jpg`;
export const DEFAULT_IMAGE_ALT = 'Flood-sculpted walls of Leprechaun Canyon';

export default {
  IS_DEVELOP,
  PLATFORM_URL,
};
