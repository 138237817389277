// @flow
import type { FrontMatter } from '../../utils/types';
import route from '../../routes';
import {
  DEFAULT_IMAGE,
  DEFAULT_IMAGE_ALT,
  IMAGE_DIRECTORY,
} from '../../utils/globals';

const frontMatterCanyoneeringMap: { [string]: FrontMatter } = {
  canyoneering: {
    id: 'canyoneering',
    title: 'How to Canyoneer',
    route: route.page_canyoneering,
    preview: 'Learn to canyoneer safely and efficiently',
    image: DEFAULT_IMAGE,
    image_alt: DEFAULT_IMAGE_ALT,
    parent: 'root',
    children: [
      'glossary',
      'pronunciation',
      'canyon-rating-system',
      'anchors',
      'check',
      'step',
      'pull',
      'partner-capture',
      'rope',
      'carabiner',
      'water-bottle',
      'quizzes',
    ],
  },
  glossary: {
    id: 'glossary',
    title: 'Glossary',
    route: route.page_glossary,
    preview: 'Glossary of Colorado Plateau canyoneering terms',
    image: DEFAULT_IMAGE,
    image_alt: DEFAULT_IMAGE_ALT,
    parent: 'canyoneering',
    children: [],
  },
  pronunciation: {
    id: 'pronunciation',
    title: 'Pronunciation',
    route: route.page_pronunciation,
    preview: 'Pronunciation guide to canyons and canyoneering terms',
    image: DEFAULT_IMAGE,
    image_alt: DEFAULT_IMAGE,
    parent: 'canyoneering',
    children: [],
  },
  'canyon-rating-system': {
    id: 'canyon-rating-system',
    title: 'Canyon Rating System',
    route: route.page_canyon_rating_system,
    preview: 'Summary of ACA Canyon Rating System',
    image: DEFAULT_IMAGE,
    image_alt: DEFAULT_IMAGE_ALT,
    parent: 'canyoneering',
    children: [],
  },
  'other-canyon-rating-systems': {
    id: 'other-canyon-rating-systems',
    title: 'Other Canyon Rating Systems',
    route: route.page_other_canyon_rating_systems,
    preview: 'List of other canyon rating systems',
    image: DEFAULT_IMAGE,
    image_alt: DEFAULT_IMAGE_ALT,
    parent: 'canyoneering',
    children: [],
  },
  anchors: {
    id: 'anchors',
    title: 'Anchors',
    route: route.page_anchors,
    preview: 'Everything I know about anchors',
    image: `${IMAGE_DIRECTORY}earnest.jpg`,
    image_alt: 'Toggle anchored to a large tree',
    parent: 'canyoneering',
    children: ['single-solid-natural-anchors', 'earnest', 'dear'],
  },
  'single-solid-natural-anchors': {
    id: 'single-solid-natural-anchors',
    title: 'Single Solid Natural Anchors',
    route: route.page_single_solid_natural_anchors,
    preview:
      'Everything I know about rocks and trees and how they can be solid rappelling anchors',
    image: `${IMAGE_DIRECTORY}single-solid-natural-anchors.jpg`,
    image_alt: 'Webbing tied around a single solid natural anchor',
    parent: 'anchors',
    children: [],
  },
  earnest: {
    id: 'earnest',
    title: 'EARNEST',
    route: route.page_earnest,
    preview:
      'Equalized Angle Redundant No Extension Solid Timely - The best way to connect webbing to anchors',
    image: `${IMAGE_DIRECTORY}earnest.jpg`,
    image_alt: 'EARNEST anchor around a tree',
    parent: 'anchors',
    children: [],
  },
  dear: {
    id: 'dear',
    title: 'DEAR',
    route: route.page_dear,
    preview:
      'Dry Efficient Accessible Rope Retrieval - Qualities of an ideal anchor location',
    image: DEFAULT_IMAGE,
    image_alt: DEFAULT_IMAGE_ALT,
    parent: 'anchors',
    children: [],
  },
  check: {
    id: 'check',
    title: 'CHECK Before Rappelling',
    route: route.page_check,
    preview:
      'C.H.E.C.K. - Acronym to remember what to check before starting a rappel',
    image: DEFAULT_IMAGE,
    image_alt: DEFAULT_IMAGE_ALT,
    parent: 'canyoneering',
    children: [],
  },
  step: {
    id: 'step',
    title: 'STEP - A Checklist for the Last Rappeller',
    route: route.page_step,
    preview: 'S.T.E.P. - Acronym for the last person on rappel',
    image: DEFAULT_IMAGE,
    image_alt: DEFAULT_IMAGE_ALT,
    parent: 'canyoneering',
    children: [],
  },
  pull: {
    id: 'pull',
    title: 'The Pull',
    route: route.page_pull,
    preview: 'Best practices for successful rope retrieval',
    image: `${IMAGE_DIRECTORY}pull.jpg`,
    image_alt: 'Stuck rope during during canyoneering skills practice session',
    parent: 'canyoneering',
    children: [],
  },
  'partner-capture': {
    id: 'partner-capture',
    title: 'Partner Capture',
    route: route.page_partner_capture,
    preview:
      'The correct way to partner capture feels unnatural and requires strict discipline from both capturers and capturee...',
    image: `${IMAGE_DIRECTORY}partner-capture.jpg`,
    image_alt: 'Pyramid capture',
    parent: 'canyoneering',
    children: [],
  },
  rope: {
    id: 'rope',
    title: 'Rope',
    route: route.page_rope,
    preview: 'All about rope in canyoneering',
    image: `${IMAGE_DIRECTORY}rope.jpg`,
    image_alt: 'Various canyoneering ropes',
    parent: 'canyoneering',
    children: [],
  },
  carabiner: {
    id: 'carabiner',
    title: 'Carabiners',
    route: route.page_carabiner,
    preview: 'All about carabiners in canyoneering',
    image: `${IMAGE_DIRECTORY}carabiner.jpg`,
    image_alt: '',
    parent: 'canyoneering',
    children: [],
  },
  'water-bottle': {
    id: 'water-bottle',
    title: 'Water Bottles',
    route: route.page_water_bottle,
    preview: 'Review of every type of water bottle I have seen in canyons',
    image: `${IMAGE_DIRECTORY}water-bottle.png`,
    image_alt: 'Collection of water bottles I have seen in canyons',
    parent: 'canyoneering',
    children: [],
  },
  quizzes: {
    id: 'quizzes',
    title: 'Quizzes',
    route: route.page_quizzes,
    preview: 'All the full-length quizzes in one place',
    image: DEFAULT_IMAGE,
    image_alt: DEFAULT_IMAGE_ALT,
    parent: 'canyoneering',
    children: [],
  },
};

export default frontMatterCanyoneeringMap;
