// @flow
import route from '../../routes';
import type { FrontMatter } from '../../utils/types';
import frontMatterCanyoneeringMap from './front-matter-canyoneering';
import frontMatterAcaMap from './front-matter-aca';
import frontMatterBlogMap from './front-matter-blog';
import { IMAGE_DIRECTORY } from '../../utils/globals';

export const frontMatterMap: { [string]: FrontMatter } = {
  root: {
    id: 'root',
    title: 'Home',
    route: route.page_root,
    preview: 'Medium quality canyoneering information',
    image: `${IMAGE_DIRECTORY}root.jpg`,
    image_alt: 'Jared washing off poison ivy',
    parent: undefined,
    children: ['canyoneering', 'aca', 'blog', 'about'],
  },
  ...frontMatterCanyoneeringMap,
  ...frontMatterAcaMap,
  ...frontMatterBlogMap,
  about: {
    id: 'about',
    title: 'About',
    route: route.page_about,
    preview: 'Learn about this medium quality canyoneer',
    image: `${IMAGE_DIRECTORY}about.jpg`,
    image_alt: 'Jared standing in Belfast Blvd',
    parent: 'root',
    children: [],
  },
};

export default frontMatterMap;
