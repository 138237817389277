// @flow
import React, { type Node } from 'react';
import styled from 'styled-components';
import type { Route } from '../utils/types';
import routes from '../routes';
import {
  LinkContainer as UglyLinkContainer,
  HeaderLink,
  Hr,
  TitleLink,
  SelectedDrawerLink,
  DrawerLink,
} from './drawer';
import frontMatterMap from '../data/front-matter';
import { border_radius } from '../utils/styles';

const LinkContainer = styled(UglyLinkContainer)`
  max-width: 300px;
  padding-top: 2rem;
  padding-bottom: 0.83333rem;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  ${border_radius};
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

export default class OpenDrawer extends React.Component<{ route: Route }> {
  render() {
    const { route } = this.props;

    const getLinks = (mainRoute: Route, currentRoute: Route): Array<Node> => {
      const frontMatter = frontMatterMap[mainRoute];
      return frontMatter.children.map((childId) => {
        const child = frontMatterMap[childId];
        return child.route === currentRoute ? (
          <SelectedDrawerLink key={child.route}>
            {child.title}
          </SelectedDrawerLink>
        ) : (
          <DrawerLink key={child.route} to={child.route}>
            {child.title}
          </DrawerLink>
        );
      });
    };

    const openSection = route.startsWith(routes.page_blog)
      ? 'blog'
      : route.startsWith(routes.page_aca)
      ? 'aca'
      : 'canyoneering';

    return (
      <LinkContainer>
        <TitleLink to="/">Canyon Companion</TitleLink>
        <Hr />
        <HeaderLink to={routes.page_canyoneering}>How to Canyoneer</HeaderLink>
        {openSection === 'canyoneering' && getLinks('canyoneering', route)}
        <Hr />
        <HeaderLink to={routes.page_aca}>ACA Checklists</HeaderLink>
        {openSection === 'aca' && getLinks('aca', route)}
        <Hr />
        <HeaderLink to={routes.page_blog}>Blog</HeaderLink>
        {openSection === 'blog' && getLinks('blog', route)}
        <Hr />
        <HeaderLink to={routes.page_about}>About</HeaderLink>
      </LinkContainer>
    );
  }
}
