// @flow
import React, { type Node } from 'react';
import ReactMotionDrawer from 'react-motion-drawer';
import { Link as GatsbyLink } from 'gatsby';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import routes from '../routes';
import type { Route } from '../utils/types';
import { color_primary, large_screen } from '../utils/styles';
import frontMatterMap from '../data/front-matter';

const padding = 0.83333;

const CloseButton = styled(MdClose).attrs({
  size: 24,
  color: 'black',
})`
  cursor: pointer;
  padding: 16px;
  border-radius: 28px;
  transition: background-color 300ms;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  &:active {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;

const StyledDrawer = styled(ReactMotionDrawer).attrs({
  fadeOut: true,
  panTolerance: 99,
})`
  background: white;
  ${large_screen} {
    padding: 16px;
  }
  display: flex;
  flex-direction: column;
`;

export const LinkContainer = styled.div`
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
`;

export const DrawerLink = styled(GatsbyLink)`
  color: unset;
  padding: ${padding}rem;
  margin: 0;
  transition: background-color 300ms ease-out, color 300ms ease-out;

  &:hover,
  &:active {
    color: unset;
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export const SelectedDrawerLink = styled.p`
  color: ${color_primary};
  font-style: italic;
  margin: 0;
  padding: ${padding}rem;
`;

export const TitleLink = styled(DrawerLink)`
  color: rgba(0, 0, 0, 0.5);
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  font-size: 1.51572rem;
  line-height: 1.1;
  padding: ${padding * 2}rem ${padding}rem;
`;

export const HeaderLink = styled(DrawerLink)`
  color: rgba(0, 0, 0, 0.5);
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  font-size: 1.31951rem;
  line-height: 1.1;
  padding: ${padding}rem;
`;

export const Hr = styled.hr`
  margin: ${padding}rem;
`;

type Props = {
  open: boolean,
  onChange: (open: boolean) => void,
  route: Route,
};

export default class Drawer extends React.Component<Props> {
  escFunction = (event: SyntheticEvent<HTMLButtonElement>) => {
    //$FlowFixMe: Figure out how to find event.keyCode
    if (event.keyCode === 27) {
      this.closeDrawer();
    }
  };

  componentDidMount() {
    //$FlowFixMe: Figure out how to find document.addEventListener
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    //$FlowFixMe: Figure out how to find document.removeEventListener
    document.removeEventListener('keydown', this.escFunction, false);
  }

  render() {
    const { open, onChange, route } = this.props;

    const getLinks = (mainRoute: Route, currentRoute: Route): Array<Node> => {
      const frontMatter = frontMatterMap[mainRoute];
      return frontMatter.children.map((childId) => {
        const child = frontMatterMap[childId];
        return child.route === currentRoute ? (
          <SelectedDrawerLink key={child.route}>
            {child.title}
          </SelectedDrawerLink>
        ) : (
          <DrawerLink key={child.route} to={child.route}>
            {child.title}
          </DrawerLink>
        );
      });
    };

    const openSection = route.startsWith(routes.page_blog)
      ? 'blog'
      : route.startsWith(routes.page_aca)
      ? 'aca'
      : 'canyoneering';

    return (
      <StyledDrawer open={open} onChange={onChange}>
        <CloseButton onClick={this.closeDrawer} />
        <LinkContainer>
          <TitleLink to="/">Canyon Companion</TitleLink>
          <Hr />
          <HeaderLink to={routes.page_canyoneering}>
            How to Canyoneer
          </HeaderLink>
          {openSection === 'canyoneering' && getLinks('canyoneering', route)}
          <Hr />
          <HeaderLink to={routes.page_aca}>ACA Checklists</HeaderLink>
          {openSection === 'aca' && getLinks('aca', route)}
          <Hr />
          <HeaderLink to={routes.page_blog}>Blog</HeaderLink>
          {openSection === 'blog' && getLinks('blog', route)}
          <Hr />
          <HeaderLink to={routes.page_about}>About</HeaderLink>
        </LinkContainer>
      </StyledDrawer>
    );
  }

  closeDrawer = () => {
    const { open, onChange } = this.props;
    open && onChange(false);
  };
}
