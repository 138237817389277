// @flow
import type { FrontMatter } from '../../utils/types';
import route from '../../routes';
import { IMAGE_DIRECTORY } from '../../utils/globals';

const frontMatterAcaMap: { [string]: FrontMatter } = {
  aca: {
    id: 'aca',
    title: 'American Canyoneering Association',
    route: route.page_aca,
    preview: 'All about the American Canyoneering Association',
    image: `${IMAGE_DIRECTORY}aca.png`,
    image_alt: 'ACA Logo',
    parent: 'root',
    children: [
      'core-skills-checklist',
      'aspirant-skills-checklist',
      'cl1-skills-checklist',
      'cl2-skills-checklist',
      '2018-skills-checklist',
    ],
  },
  'core-skills-checklist': {
    id: 'core-skills-checklist',
    title: 'Core Skills Checklist',
    route: route.aca_core_skills_checklist,
    preview:
      'Provide students with the fundamental canyoneering skills necessary to join a competently led group into canyons rated up to 3A/B III and supervised introduction to technical canyoneering skills.',
    image: `${IMAGE_DIRECTORY}aca.png`,
    image_alt: 'ACA Logo',
    parent: 'aca',
    children: [],
  },
  'aspirant-skills-checklist': {
    id: 'aspirant-skills-checklist',
    title: 'Aspirant Skills Checklist',
    route: route.aca_aspirant_skills_checklist,
    preview:
      'Through training and mentoring, increase of technical personal skill sets and expanded situational awareness and to introduce group leadership roles, associated technical skills and instructional techniques.',
    image: `${IMAGE_DIRECTORY}aca.png`,
    image_alt: 'ACA Logo',
    parent: 'aca',
    children: [],
  },
  'cl1-skills-checklist': {
    id: 'cl1-skills-checklist',
    title: 'Canyon Leader 1 Skills Checklist',
    route: route.aca_cl1_skills_checklist,
    preview:
      'The development of intermediate level canyoneering / problem solving skills, basic canyon leadership techniques, group efficiencies, and competence in straightforward companion rescues.',
    image: `${IMAGE_DIRECTORY}aca.png`,
    image_alt: 'ACA Logo',
    parent: 'aca',
    children: [],
  },
  'cl2-skills-checklist': {
    id: 'cl2-skills-checklist',
    title: 'Canyon Leader 2 Skills Checklist',
    route: route.aca_cl2_skills_checklist,
    preview:
      'OBJECTIVE: To be effective in the safe leadership of a canyoneering group, competent in mitigating technical challenges, confident in environmental/situational risk assessment, and capable in an emergency.',
    image: `${IMAGE_DIRECTORY}aca.png`,
    image_alt: 'ACA Logo',
    parent: 'aca',
    children: [],
  },
  '2018-skills-checklist': {
    id: '2018-skills-checklist',
    title: '2018 ACA Skills Checklist',
    route: route.aca_2018_skills_checklist,
    preview: 'Older (2018) version of the ACA Skills Checklist',
    image: `${IMAGE_DIRECTORY}aca.png`,
    image_alt: 'ACA Logo',
    parent: 'aca',
    children: [],
  },
};

export default frontMatterAcaMap;
