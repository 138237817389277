// @flow
import React, { type Node } from 'react';
import styled from 'styled-components';
import { MdAdd, MdRemove } from 'react-icons/md';
import { border } from '../utils/styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${border};
  margin-bottom: calc(1.66667rem - 1px);
`;

const Section = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
  font-size: 90%;

  transition: background-color 300ms;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  &:active {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;

const PlusButton = styled(MdAdd).attrs({
  size: 30,
  color: 'rgba(0, 0, 0, 0.5)',
})`
  margin: 0.66667rem;
`;

const MinusButton = styled(MdRemove).attrs({
  size: 30,
  color: 'rgba(0, 0, 0, 0.5)',
})`
  margin: 0.66667rem;
`;

type Props = {
  hint: string,
  children: Node,
  visible?: boolean,
};

type State = {
  visible: boolean,
};

export default class Collapsible extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      visible: props.visible || false,
    };
  }

  render() {
    const { visible } = this.state;
    const { hint, children, ...rest } = this.props;

    return (
      <Container {...rest}>
        {visible ? (
          <>
            <Section onClick={this.onClick}>
              <MinusButton />
            </Section>
            {children}
          </>
        ) : (
          <Section onClick={this.onClick}>
            <PlusButton />
            {hint}
          </Section>
        )}
      </Container>
    );
  }

  onClick = () => {
    const { visible } = this.state;
    this.setState({ visible: !visible });
  };
}
