// @flow
import { css } from 'styled-components';

//region Colors

export const color_primary = '#ff8700';
export const color_light = '#ffcc80';
export const color_border = 'rgba(0, 0, 0, 0.2)';
export const color_text = 'rgba(0, 0, 0, 0.87)';
export const color_header = 'rgba(0, 0, 0, 0.5)';

//endregion Colors

//region Media Query

export const threshold = 800;

export const small_screen = `@media only screen and (max-width: ${threshold}px)`;

export const large_screen = `@media only screen and (min-width: ${threshold}px)`;

//endregion Media Query

//region border-radius

export const border_radius = css`
  border-radius: 8px;
  ${small_screen} {
    border-radius: 4px;
  }
`;

export const border = css`
  border: 1px solid ${color_border};
  ${border_radius};
`;

//endregion border-radius
