// @flow
import React from 'react';
import styled from 'styled-components';
import {
  getPreviousSibling,
  getNextSibling,
} from '../utils/front-matter-helpers';
import UnstyledLink from './link';
import type { Id } from '../utils/types';

const Container = styled.p`
  display: flex;
  justify-content: space-between;
`;

const Link = styled(UnstyledLink)`
  max-width: 50%;
`;

const Filler = styled.span`
  flex-grow: 1;
`;

export default class PreviousNextPages extends React.Component<{ id: Id }> {
  render() {
    const { id } = this.props;

    const prev = getPreviousSibling(id);
    const next = getNextSibling(id);

    return (
      <Container>
        {prev && (
          <Link
            to={prev.route}
            title={prev.preview}
          >{`Previous Page: ${prev.title}`}</Link>
        )}
        <Filler />
        {next && (
          <Link
            to={next.route}
            title={next.preview}
          >{`Next Page: ${next.title}`}</Link>
        )}
      </Container>
    );
  }
}
