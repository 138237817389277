// @flow
import { PLATFORM_URL } from './globals';
import axios, { type AxiosRequestConfig } from 'axios';

const headers: AxiosRequestConfig = { 'X-Requested-With': 'XMLHttpRequest' };

export default {
  delete: (url: string) => axios.delete(PLATFORM_URL + url, headers),
  get: (url: string) => axios.get(PLATFORM_URL + url, headers),
  post: (url: string, data?: any) =>
    axios.post(PLATFORM_URL + url, data, headers),
  put: (url: string, data?: any) =>
    axios.put(PLATFORM_URL + url, data, headers),
};
