// @flow
import React, { type Node } from 'react';

const mapWithDelimiter = (nodes: Array<Node>, delimiter: Node): Node => {
  const array: Array<Node> = [];
  for (let i = 0; i < nodes.length; i++) {
    if (i !== 0) {
      array.push(<span key={`delimiter-${i}`}>{delimiter}</span>);
    }
    array.push(nodes[i]);
  }
  return <>{array}</>;
};

export default mapWithDelimiter;
