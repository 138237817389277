// @flow
import React from 'react';
import styled from 'styled-components';
import { MdKeyboardArrowUp } from 'react-icons/md';
import { large_screen } from '../utils/styles';

const ArrowUpwardIcon = styled(MdKeyboardArrowUp).attrs({
  size: 24,
  color: 'black',
})``;

const Container = styled.a.attrs({
  children: <ArrowUpwardIcon />,
})`
  position: fixed;
  bottom: 0;
  right: 0;
  cursor: pointer;
  z-index: 1;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 16px;
  border-radius: 28px;
  line-height: 12px;
  transition: background-color 300ms;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  &:active {
    background-color: rgba(0, 0, 0, 0.08);
  }

  ${large_screen} {
    margin: 16px;
  }
`;

type Props = {
  displayBelow: number,
};

type State = {
  show: boolean,
};

export default class ScrollUpButton extends React.Component<Props, State> {
  state = {
    show: false,
  };

  static defaultProps = {
    displayBelow: 183,
  };

  handleScrollChange = () => {
    const show = window.pageYOffset > this.props.displayBelow;
    if (this.state.show !== show) {
      this.setState({ show });
    }
  };

  componentDidMount() {
    this.handleScrollChange();
    window.addEventListener('scroll', this.handleScrollChange);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScrollChange);
  }

  render() {
    if (!this.state.show) {
      return null;
    }

    return <Container id="scroll-up-button" onClick={this.handleClick} />;
  }

  handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
}
