// @flow
import React, { type Node } from 'react';
import styled from 'styled-components';
import { term2id } from '../utils/key';
import Link from './link';
import { color_header, large_screen } from '../utils/styles';
import Collapsible from './collapsible';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 calc(0.833333rem - 1px);

  ${large_screen} {
    padding: 0 calc(1.66667rem - 1px);
  }
`;

// $FlowFixMe styled-components-v4
const SelfLink = styled(Link).attrs({
  hideExternalLink: true,
})`
  color: unset;

  &:hover,
  &:active {
    color: unset;
    text-decoration: underline ${color_header} solid;
  }
`;

const isH2 = (child) => {
  return (
    child && child.type && (child.type === 'h2' || child.type.target === 'h2')
  );
};

type Props = {
  children: Node,
  startVisible?: boolean,
};

export default class TableOfContents extends React.PureComponent<Props> {
  render() {
    const { children, startVisible = false } = this.props;

    const h2s = React.Children.toArray(children)
      .filter(isH2)
      .map((h2) => {
        const text = h2.props.children;
        const id = h2.props.id || term2id(text);

        return (
          <li key={id}>
            <Link key={id} to={`#${id}`}>
              {text}
            </Link>
          </li>
        );
      });

    return (
      <Collapsible hint="Contents" visible={startVisible}>
        <Container>
          <ol>{h2s}</ol>
        </Container>
      </Collapsible>
    );
  }
}

export const injectTocIds = (children: Node) => {
  return React.Children.map(children, (child) => {
    if (!isH2(child)) {
      return child;
    }

    const id = child.props.id || term2id(child.props.children);

    const newChild = React.cloneElement(child, { id });

    return <SelfLink to={`#${id}`}>{newChild}</SelfLink>;
  });
};
