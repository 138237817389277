// @flow
import type { FrontMatter } from '../../utils/types';
import route from '../../routes';
import {
  DEFAULT_IMAGE,
  DEFAULT_IMAGE_ALT,
  IMAGE_DIRECTORY,
} from '../../utils/globals';

const frontMatterBlogMap: { [string]: FrontMatter } = {
  blog: {
    id: 'blog',
    title: 'Blog',
    route: route.page_blog,
    preview: 'Great quality trips from a medium quality canyoneer',
    image: DEFAULT_IMAGE,
    image_alt: DEFAULT_IMAGE_ALT,
    parent: 'root',
    children: [
      '2021-08-08-change-creek',
      '2021-08-07-hall-creek',
      '2021-08-06-south-fork-snoqualmie',
      '2021-07-05-adams-canyon',
      '2020-09-27-great-white-icicle',
      '2020-09-07-super-amazing-ropewiki-map',
      '2020-07-24-crumbling-canyon',
      '2020-07-12-lincolns-first-canyon',
      '2020-04-26-aca-skills-checklists-updates',
      '2020-02-23-the-2020-utah-canyoneers-shirt',
      '2019-06-30-every-mistake',
      '2019-06-25-worrier',
      '2019-06-15-rocky-mouth',
      '2019-05-18-rappel-maui',
      '2019-03-16-north-wash',
      '2018-11-11-lomatium',
      '2018-11-10-tierdrop-not-tierdrop',
      '2018-11-09-moonflower-u-turn',
      '2018-10-26-spencer',
      '2018-09-22-rock-canyon',
      '2018-09-21-engelstead',
      '2018-09-20-mystery-revisited',
      '2018-09-10-subway',
      '2018-05-19-robbers-roost',
      '2018-04-19-halls-creek',
      '2018-04-15-one-hundred-canyons-later',
      '2018-03-24-gla-rendezvous',
      '2018-03-03-sandthrax',
      '2018-03-03-no-kidding',
      '2018-03-02-monkey-business',
      '2017-12-05-canyons-no-longer-within-grand-staircase-escalante-national-monument',
      '2017-11-11-williams-first-rappel',
      '2017-09-30-dry-canyon-practice',
      '2017-06-10-heaps',
      '2017-06-09-lower-refrigerator',
      '2017-06-08-fat-mans-misery',
      '2016-10-15-aca-leaderguide-rendezvous-egypt-1',
      '2016-10-15-aca-leaderguide-rendezvous-beau-beau',
      '2016-10-14-aca-leaderguide-rendezvous-day-1',
      '2016-09-25-dry-canyon-fall-colors',
      '2016-09-18-bensen-creek',
      '2016-09-16-hidden',
      '2016-05-14-neon-choprock',
      '2015-10-23-egypt-2',
      '2014-09-20-rockfall-in-mystery-canyon',
      '2014-08-01-kanarra-creek',
      '2012-04-28-my-story',
    ],
  },
  '2012-04-28-my-story': {
    id: '2012-04-28-my-story',
    title: 'My Story',
    route: route.blog_2012_04_28_my_story,
    preview: 'How I got into canyoneering...',
    date: [2012, 4, 28],
    image: `${IMAGE_DIRECTORY}about.jpg`,
    image_alt: 'Jared standing in Belfast Blvd',
    parent: 'blog',
    children: [],
  },
  '2014-08-01-kanarra-creek': {
    id: '2014-08-01-kanarra-creek',
    title: 'Kanarra Creek',
    route: route.blog_2014_08_01_kanarra_creek,
    preview:
      'My family took a trip to the Grand Canyon. We had a few hours set aside on the drive down to visit Kanarra Creek...',
    date: [2014, 8, 1],
    image: `${IMAGE_DIRECTORY}2014-08-01-kanarra-creek.jpg`,
    image_alt: 'Looking down first waterfall',
    parent: 'blog',
    children: [],
  },
  '2014-09-20-rockfall-in-mystery-canyon': {
    id: '2014-09-20-rockfall-in-mystery-canyon',
    title: 'Rockfall in Mystery Canyon',
    route: route.blog_2014_09_20_rockfall_in_mystery_canyon,
    preview:
      'At the 2014 Zion Canyon Rendezvous, I had my canyon leadership abilities tested for the first time under a stressful situation...',
    date: [2014, 9, 20],
    image: `${IMAGE_DIRECTORY}2014-09-20-rockfall-in-mystery-canyon.jpg`,
    image_alt:
      'Jared simulating leaning into a dry fall to protect himself from an up canyon rock fall',
    parent: 'blog',
    children: [],
  },
  '2015-10-23-egypt-2': {
    id: '2015-10-23-egypt-2',
    title: 'Egypt 2',
    route: route.blog_2015_10_23_egypt_2,
    preview:
      'Egypt 2 was my first Escalante canyon. The 270′ entrance rappel provided a great introduction to this spectacular area...',
    date: [2015, 10, 23],
    image: `${IMAGE_DIRECTORY}2015-10-23-egypt-2.jpg`,
    image_alt: 'Skinny canyon walls of Egypt 2 canyon',
    parent: 'blog',
    children: [],
  },
  '2016-05-14-neon-choprock': {
    id: '2016-05-14-neon-choprock',
    title: 'Neon and Choprock Canyons',
    route: route.blog_2016_05_14_neon_choprock,
    preview: 'I finally descended Neon and Choprock canyons...',
    date: [2016, 5, 14],
    image: `${IMAGE_DIRECTORY}2016-05-14-neon-choprock.jpg`,
    image_alt: 'Walking through the Happy Section of Choprock Canyon',
    parent: 'blog',
    children: [],
  },
  '2016-09-16-hidden': {
    id: '2016-09-16-hidden',
    title: 'Hidden Canyon',
    route: route.blog_2016_09_16_hidden,
    preview:
      "After a fun day leading Behunin, we got invited to do Hidden. I was pretty sick at the time and didn't want to do anything too difficult, so Hidden it was...",
    date: [2016, 9, 16],
    image: `${IMAGE_DIRECTORY}2016-09-16-hidden.jpg`,
    image_alt: 'At the top looking down into Hidden Canyon',
    parent: 'blog',
    children: [],
  },
  '2016-09-18-bensen-creek': {
    id: '2016-09-18-bensen-creek',
    title: 'Bensen Creek',
    route: route.blog_2016_09_18_bensen_creek,
    preview:
      'Sunday morning following the Zion Canyoneering Rendezvous we had some time so we decided to descend Benson Creek near Brian Head...',
    date: [2016, 9, 18],
    image: `${IMAGE_DIRECTORY}2016-09-18-bensen-creek.jpg`,
    image_alt: 'Rappel in Bensen Creek',
    parent: 'blog',
    children: [],
  },
  '2016-09-25-dry-canyon-fall-colors': {
    id: '2016-09-25-dry-canyon-fall-colors',
    title: 'Dry Canyon Fall Colors',
    route: route.blog_2016_09_25_dry_canyon_fall_colors,
    preview:
      'Autumn colors were in full force high up in the canyons below Timpanogos...',
    date: [2016, 9, 25],
    image: `${IMAGE_DIRECTORY}2016-09-25-dry-canyon-fall-colors.jpg`,
    image_alt: 'Red autumn tree surrounded by winter white',
    parent: 'blog',
    children: [],
  },
  '2016-10-14-aca-leaderguide-rendezvous-day-1': {
    id: '2016-10-14-aca-leaderguide-rendezvous-day-1',
    title: 'ACA Leader/Guide Rendezvous: Day 1',
    route: route.blog_2016_10_14_aca_leaderguide_rendezvous_day_1,
    preview:
      'This is just a short summary of some of the things we covered on the first day of the Leader/Guide Rendezvous...',
    date: [2016, 10, 14],
    image: `${IMAGE_DIRECTORY}2016-10-14-aca-leaderguide-rendezvous-day-1.jpg`,
    image_alt: 'Rich Carlson demonstrating use of an European Death Knot',
    parent: 'blog',
    children: [],
  },
  '2016-10-15-aca-leaderguide-rendezvous-beau-beau': {
    id: '2016-10-15-aca-leaderguide-rendezvous-beau-beau',
    title: 'ACA Leader/Guide Rendezvous: Beau Beau',
    route: route.blog_2016_10_15_aca_leaderguide_rendezvous_beau_beau,
    preview:
      'After ascending and hauling in Egypt 1 I went with a group to Beau Beau aka Egypt 1.5...',
    date: [2016, 10, 15],
    image: `${IMAGE_DIRECTORY}2016-10-15-aca-leaderguide-rendezvous-beau-beau.jpg`,
    image_alt: 'Snake making its way out of Beau Beau',
    parent: 'blog',
    children: [],
  },
  '2016-10-15-aca-leaderguide-rendezvous-egypt-1': {
    id: '2016-10-15-aca-leaderguide-rendezvous-egypt-1',
    title: 'ACA Leader/Guide Rendezvous: Egypt 1',
    route: route.blog_2016_10_15_aca_leaderguide_rendezvous_egypt_1,
    preview:
      'The objective for this canyon was to go down a ways and then ascend back up all the rappels. It made the canyon quite fun...',
    date: [2016, 10, 15],
    image: `${IMAGE_DIRECTORY}2016-10-15-aca-leaderguide-rendezvous-egypt-1.jpg`,
    image_alt: 'Tarantula making its way up canyon',
    parent: 'blog',
    children: [],
  },
  '2017-06-08-fat-mans-misery': {
    id: '2017-06-08-fat-mans-misery',
    title: "Fat Man's Misery June 2017",
    route: route.blog_2017_06_08_fat_mans_misery,
    preview:
      'This is the first time I have ever dropped into the wrong drainage...',
    date: [2017, 6, 8],
    image: `${IMAGE_DIRECTORY}2017-06-08-fat-mans-misery.jpg`,
    image_alt: "Arch in the lower section of Fat Man's Misery",
    parent: 'blog',
    children: [],
  },
  '2017-06-09-lower-refrigerator': {
    id: '2017-06-09-lower-refrigerator',
    title: 'Lower Refrigerator June 2017',
    route: route.blog_2017_06_09_lower_refrigerator,
    preview:
      'Lower Refrigerator has the distinction of being the canyon that is not only Mother Nature’s trash bin but Angel’s Landing hikers’ trash bin as well...',
    date: [2017, 6, 9],
    image: `${IMAGE_DIRECTORY}2017-06-09-lower-refrigerator.jpg`,
    image_alt: 'Looking down Zion Canyon',
    parent: 'blog',
    children: [],
  },
  '2017-06-10-heaps': {
    id: '2017-06-10-heaps',
    title: 'Heaps June 2017',
    route: route.blog_2017_06_10_heaps,
    preview: 'Heaps, the biggest, baddest, best canyon in Zion...',
    date: [2017, 6, 10],
    image: `${IMAGE_DIRECTORY}2017-06-10-heaps.jpg`,
    image_alt: 'Final rappel of heaps into Upper Emerald Pool',
    parent: 'blog',
    children: [],
  },
  '2017-09-30-dry-canyon-practice': {
    id: '2017-09-30-dry-canyon-practice',
    title: 'Dry Canyon Practice Sep 2017',
    route: route.blog_2017_09_30_dry_canyon_practice,
    preview:
      'I got to host an ACA Practice Session up Dry Canyon in Utah Valley...',
    date: [2017, 9, 30],
    image: `${IMAGE_DIRECTORY}2017-09-30-dry-canyon-practice.jpg`,
    image_alt: 'Looking up Dry Canyon from the top of the rappel',
    parent: 'blog',
    children: [],
  },
  '2017-11-11-williams-first-rappel': {
    id: '2017-11-11-williams-first-rappel',
    title: "William's First Rappel",
    route: route.blog_2017_11_11_williams_first_rappel,
    preview:
      'William is always asking if he can go canyoneering with me. We finally made it happen...',
    date: [2017, 11, 11],
    image: `${IMAGE_DIRECTORY}2017-11-11-williams-first-rappel.jpg`,
    image_alt: 'William on his first rappel',
    parent: 'blog',
    children: [],
  },
  '2017-12-05-canyons-no-longer-within-grand-staircase-escalante-national-monument':
    {
      id: '2017-12-05-canyons-no-longer-within-grand-staircase-escalante-national-monument',
      title:
        'Canyons No Longer within Grand Staircase-Escalante National Monument',
      route:
        route.blog_2017_12_05_canyons_no_longer_within_grand_staircase_escalante_national_monument,
      preview:
        'The following canyons will no longer be located within the Grand Staircase-Escalante National Monument...',
      date: [2017, 3, 24],
      image: `${IMAGE_DIRECTORY}2017-12-05-canyons-no-longer-within-grand-staircase-escalante-national-monument.jpg`,
      image_alt:
        'Screenshot of Egypt drainage - one section of canyon that is no longer part of the monument',
      parent: 'blog',
      children: [],
    },
  '2018-03-02-monkey-business': {
    id: '2018-03-02-monkey-business',
    title: 'Monkey Business',
    route: route.blog_2018_03_02_monkey_business,
    preview:
      'My first canyoneering trip of 2018 kicked off in North Wash with the spectacular Monkey Business...',
    date: [2018, 3, 2],
    image: `${IMAGE_DIRECTORY}2018-03-02-monkey-business.jpg`,
    image_alt: 'Group picture after descending Monkey Business',
    parent: 'blog',
    children: [],
  },
  '2018-03-03-no-kidding': {
    id: '2018-03-03-no-kidding',
    title: 'No Kidding',
    route: route.blog_2018_03_03_no_kidding,
    preview: 'Great sand trap practice in No Kidding Canyon...',
    date: [2018, 3, 3],
    image: `${IMAGE_DIRECTORY}2018-03-03-no-kidding.jpg`,
    image_alt: 'Rappelling off of a sand trap in No Kidding',
    parent: 'blog',
    children: [],
  },
  '2018-03-03-sandthrax': {
    id: '2018-03-03-sandthrax',
    title: 'Sandthrax',
    route: route.blog_2018_03_03_sandthrax,
    preview:
      'Starting out scared and timid, I finished my first X-rated canyon happy and confidently..',
    date: [2018, 3, 3],
    image: `${IMAGE_DIRECTORY}2018-03-03-sandthrax.jpg`,
    image_alt: 'Contemplating the up climb in Sandthrax',
    parent: 'blog',
    children: [],
  },
  '2018-03-24-gla-rendezvous': {
    id: '2018-03-24-gla-rendezvous',
    title: 'ACA Guide Leader Aspirant Rendezvous',
    route: route.blog_2018_03_24_gla_rendezvous,
    preview: 'Fun times practicing skills and meeting new aspirants...',
    date: [2018, 3, 24],
    image: `${IMAGE_DIRECTORY}2018-03-24-gla-rendezvous.jpg`,
    image_alt: '3-person pyramid capture',
    parent: 'blog',
    children: [],
  },
  '2018-04-15-one-hundred-canyons-later': {
    id: '2018-04-15-one-hundred-canyons-later',
    title: 'One Hundred Canyons Later',
    route: route.blog_2018_04_15_one_hundred_canyons_later,
    preview:
      'Review of and love letter to my recently retired Petzl Canyon harness...',
    date: [2018, 4, 15],
    image: `${IMAGE_DIRECTORY}2018-04-15-one-hundred-canyons-later.jpg`,
    image_alt: 'My poor harness one hundred canyons later',
    parent: 'blog',
    children: [],
  },
  '2018-04-19-halls-creek': {
    id: '2018-04-19-halls-creek',
    title: "Hall's Creek",
    route: route.blog_2018_04_19_halls_creek,
    preview:
      'Two years after bailing out, I finally got another crack at Poe...',
    date: [2018, 4, 19],
    image: `${IMAGE_DIRECTORY}2018-04-19-halls-creek.jpg`,
    image_alt: 'Traversing the Wart hole in Poe',
    parent: 'blog',
    children: [],
  },
  '2018-05-19-robbers-roost': {
    id: '2018-05-19-robbers-roost',
    title: "Robber's Roost",
    route: route.blog_2018_05_19_robbers_roost,
    preview:
      "I had a free weekend and met up with a group for Robber's Roost...",
    date: [2018, 5, 19],
    image: `${IMAGE_DIRECTORY}2018-05-19-robbers-roost.jpg`,
    image_alt: 'Looking up a chamber in Chambers',
    parent: 'blog',
    children: [],
  },
  '2018-09-10-subway': {
    id: '2018-09-10-subway',
    title: 'The Subway',
    route: route.blog_2018_09_10_subway,
    preview:
      'A friend who has never gone canyoneering before told me he put in for permits for The Subway...',
    date: [2018, 9, 10],
    image: `${IMAGE_DIRECTORY}2018-09-10-subway.jpg`,
    image_alt: 'Enjoying the iconic Subway section',
    parent: 'blog',
    children: [],
  },
  '2018-09-20-mystery-revisited': {
    id: '2018-09-20-mystery-revisited',
    title: 'Mystery Revisited',
    route: route.blog_2018_09_20_mystery_revisited,
    preview:
      'The Zion Canyoneering Rendezvous kicked off with Mystery Canyon...',
    date: [2018, 9, 20],
    image: `${IMAGE_DIRECTORY}2018-09-20-mystery-revisited.jpg`,
    image_alt: 'The top of the Dam in Mystery Canyon',
    parent: 'blog',
    children: [],
  },
  '2018-09-21-engelstead': {
    id: '2018-09-21-engelstead',
    title: 'Engelstead',
    route: route.blog_2018_09_21_engelstead,
    preview: 'Day 2 of the 2018 Zion Canyon Rendezvous...',
    date: [2018, 9, 21],
    image: `${IMAGE_DIRECTORY}2018-09-21-engelstead.jpg`,
    image_alt: 'Down climbing a rappel in Engelstead Canyon',
    parent: 'blog',
    children: [],
  },
  '2018-09-22-rock-canyon': {
    id: '2018-09-22-rock-canyon',
    title: 'Rock Canyon',
    route: route.blog_2018_09_22_rock_canyon,
    preview: 'Final day of the 2018 Zion Canyon Rendezvous...',
    date: [2018, 9, 22],
    image: `${IMAGE_DIRECTORY}2018-09-22-rock-canyon.jpg`,
    image_alt: 'First rappel in Rock Canyon',
    parent: 'blog',
    children: [],
  },
  '2018-10-26-spencer': {
    id: '2018-10-26-spencer',
    title: 'Spencer',
    route: route.blog_2018_10_26_spencer,
    preview:
      'The rendezvous kicked off with a trip down full Spencer Canyon...',
    date: [2018, 10, 26],
    image: `${IMAGE_DIRECTORY}2018-10-26-spencer.jpg`,
    image_alt: 'Sandy stretch in Spencer Canyon',
    parent: 'blog',
    children: [],
  },
  '2018-11-09-moonflower-u-turn': {
    id: '2018-11-09-moonflower-u-turn',
    title: 'Moonflower and U-Turn',
    route: route.blog_2018_11_09_moonflower_u_turn,
    preview: 'We learned the hard way that our ropes were too short...',
    date: [2018, 11, 9],
    image: `${IMAGE_DIRECTORY}2018-11-09-moonflower-u-turn.jpg`,
    image_alt: 'Approach by Colorado River',
    parent: 'blog',
    children: [],
  },
  '2018-11-10-tierdrop-not-tierdrop': {
    id: '2018-11-10-tierdrop-not-tierdrop',
    title: 'Tierdrop and Not Tierdrop',
    route: route.blog_2018_11_10_tierdrop_not_tierdrop,
    preview:
      'Day 2 of our canyoneering trip we met up with a group of people to do the Tierdrops...',
    date: [2018, 11, 10],
    image: `${IMAGE_DIRECTORY}2018-11-10-tierdrop-not-tierdrop.jpg`,
    image_alt: 'Group shot at the final rappel of Tierdrop',
    parent: 'blog',
    children: [],
  },
  '2018-11-11-lomatium': {
    id: '2018-11-11-lomatium',
    title: 'Lomatium',
    route: route.blog_2018_11_11_lomatium,
    preview:
      'For the final day of the trip we had gotten Fiery Furnace permits and intended to descend Lomatium and Krill canyons...',
    date: [2018, 11, 11],
    image: `${IMAGE_DIRECTORY}2018-11-11-lomatium.jpg`,
    image_alt: 'Enjoying one of the darker sections of Arscenic canyon',
    parent: 'blog',
    children: [],
  },
  '2019-03-16-north-wash': {
    id: '2019-03-16-north-wash',
    title: 'North Wash March 2019',
    route: route.blog_2019_03_16_north_wash,
    preview:
      "Near the end of 2018/2019's long winter, canyoneering season has kicked off.",
    date: [2019, 3, 16],
    image: `${IMAGE_DIRECTORY}2019-03-16-north-wash.jpg`,
    image_alt: '',
    parent: 'blog',
    children: [],
  },
  '2019-05-18-rappel-maui': {
    id: '2019-05-18-rappel-maui',
    title: 'Cascading in Maui',
    route: route.blog_2019_05_18_rappel_maui,
    preview:
      'While in Hawaii on vacation we went canyoneering with Rappel Maui',
    date: [2019, 5, 18],
    image: `${IMAGE_DIRECTORY}2019-05-18-rappel-maui.jpg`,
    image_alt: 'Rappelling in Hawaii with Rappel Maui',
    parent: 'blog',
    children: [],
  },
  '2019-06-15-rocky-mouth': {
    id: '2019-06-15-rocky-mouth',
    title: 'Rocky Mouth in Hard Mode',
    route: route.blog_2019_06_15_rocky_mouth,
    preview:
      "10 things I learned - and 1 thing I didn't - in my first ridiculously hard waterfall rappel",
    date: [2019, 6, 15],
    image: `${IMAGE_DIRECTORY}2019-06-15-rocky-mouth.jpg`,
    image_alt: '',
    parent: 'blog',
    children: [],
  },
  '2019-06-25-worrier': {
    id: '2019-06-25-worrier',
    title: 'The Worrier',
    route: route.blog_2019_06_25_worrier,
    preview: 'Worrying is my super power to get me through hard canyons',
    date: [2019, 6, 25],
    image: `${IMAGE_DIRECTORY}root.jpg`,
    image_alt:
      'Worrying about poison ivy that had probably all washed off hours ago',
    parent: 'blog',
    children: [],
  },
  '2019-06-30-every-mistake': {
    id: '2019-06-30-every-mistake',
    title: "Every Mistake I've Ever Made Canyoneering",
    route: route.blog_2019_06_30_every_mistake,
    preview: '',
    date: [2019, 6, 20],
    image: DEFAULT_IMAGE,
    image_alt: DEFAULT_IMAGE_ALT,
    parent: 'blog',
    children: [],
  },
  '2020-02-23-the-2020-utah-canyoneers-shirt': {
    id: '2020-02-23-the-2020-utah-canyoneers-shirt',
    title: 'Rappel Devices of the 2020 Utah Canyoneers Shirt',
    route: route.blog_2020_02_23_the_2020_utah_canyoneers_shirt,
    preview:
      "List of all the rappel devices on the 2020 Utah Canyoneers shirt and a couple rappel devices that aren't",
    date: [2020, 2, 23],
    image: `${IMAGE_DIRECTORY}2020-02-23-the-2020-utah-canyoneers-shirt.png`,
    image_alt: '2020 Utah Canyoneers shirt',
    parent: 'blog',
    children: [],
  },
  '2020-04-26-aca-skills-checklists-updates': {
    id: '2020-04-26-aca-skills-checklists-updates',
    title: 'ACA Skills Checklists Updates',
    route: route.blog_2020_04_26_aca_skills_checklist_updates,
    preview:
      "The ACA recently updated all their Skills Checklists. Let's nerd out and see what changed.",
    date: [2020, 4, 26],
    image: `${IMAGE_DIRECTORY}aca.png`,
    image_alt: 'ACA Logo',
    parent: 'blog',
    children: [],
  },
  '2020-07-12-lincolns-first-canyon': {
    id: '2020-07-12-lincolns-first-canyon',
    title: "Lincoln's First Canyon",
    route: route.blog_2020_07_12_lincolns_first_canyon,
    preview:
      "Fun waterfall rappel with my middle child plus a few things that weren't quite perfect",
    date: [2020, 7, 12],
    image: `${IMAGE_DIRECTORY}2020-07-12-lincolns-first-canyon.jpg`,
    image_alt: 'Lincoln excitedly on approach to the top of Rocky Mouth',
    parent: 'blog',
    children: [],
  },
  '2020-07-24-crumbling-canyon': {
    id: '2020-07-24-crumbling-canyon',
    title: 'Crumbling Canyon',
    route: route.blog_2020_07_24_crumbling_canyon,
    preview:
      'So much better than expected and good enough to call it "meh" for after-work canyoneering',
    date: [2020, 7, 24],
    image: `${IMAGE_DIRECTORY}2020-07-24-crumbling-canyon.jpg`,
    image_alt: 'Canyoneer down climbing the final rappel of Crumbling Canyon',
    parent: 'blog',
    children: [],
  },
  '2020-09-07-super-amazing-ropewiki-map': {
    id: '2020-09-07-super-amazing-ropewiki-map',
    title: 'Super Amazing Ropewiki Map',
    route: route.blog_2020_09_07_super_amazing_ropewiki_map,
    preview:
      'My attempt at merging the simplicity of the Super Amazing Canyoneering Map and the comprehensiveness of ropewiki',
    date: [2020, 9, 7],
    image: `${IMAGE_DIRECTORY}2020-09-07-super-amazing-ropewiki-map.jpg`,
    image_alt:
      'Screenshot of Super Amazing Ropewiki Map in Google Earth App showing canyons in Utah',
    parent: 'blog',
    children: [],
  },
  '2020-09-27-great-white-icicle': {
    id: '2020-09-27-great-white-icicle',
    title: 'Great White Icicle',
    route: route.blog_2020_09_27_great_white_icicle,
    preview: 'Very cold final canyon descent of the year',
    date: [2020, 9, 27],
    image: `${IMAGE_DIRECTORY}2020-09-27-great-white-icicle.jpg`,
    image_alt: 'Second rappel in Great White Icicle',
    parent: 'blog',
    children: [],
  },
  '2021-07-05-adams-canyon': {
    id: '2021-07-05-adams-canyon',
    title: 'Adams Canyon',
    route: route.blog_2021_07_05_adams_canyon,
    preview:
      'This Independence Day weekend we got to rappel a wonderful little waterfall',
    date: [2021, 7, 5],
    image: `${IMAGE_DIRECTORY}2021-07-05-adams-canyon.jpg`,
    image_alt: 'Rappelling the final waterfall of Adams Canyon',
    parent: 'blog',
    children: [],
  },
  '2021-08-06-south-fork-snoqualmie': {
    id: '2021-08-06-south-fork-snoqualmie',
    title: 'Snoqualmie South Fork',
    route: route.blog_2021_08_06_south_fork_snoqualmie,
    preview:
      'Day 1 and 2 of swift water canyoneering course where we practiced jumps and slides',
    date: [2021, 8, 6],
    image: `${IMAGE_DIRECTORY}2021-08-06-south-fork-snoqualmie.jpg`,
    image_alt:
      'Canyoneer short rappelling into a dark chamber to avoid a slide',
    parent: 'blog',
    children: [],
  },
  '2021-08-07-hall-creek': {
    id: '2021-08-07-hall-creek',
    title: 'Hall Creek',
    route: route.blog_2021_08_07_hall_creek,
    preview:
      'Day 3 of swift water canyoneering course where we practiced technical rope work',
    date: [2021, 8, 7],
    image: `${IMAGE_DIRECTORY}2021-08-07-hall-creek.jpg`,
    image_alt: 'Canyoneers carefully navigating a slippery slope',
    parent: 'blog',
    children: [],
  },
  '2021-08-08-change-creek': {
    id: '2021-08-08-change-creek',
    title: 'Change Creek',
    route: route.blog_2021_08_08_change_creek,
    preview: 'Day 4 of swiftwater canyoneering course where we just',
    date: [2021, 8, 8],
    image: `${IMAGE_DIRECTORY}2021-08-08-change-creek.jpg`,
    image_alt: '',
    parent: 'blog',
    children: [],
  },
};

export default frontMatterBlogMap;
