// @flow
import type { Id } from './types';

export const term2id = (term: string): Id =>
  term
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^a-z0-9-]/g, '');

export const term2js = (term: string): Id =>
  term
    .toLowerCase()
    .replace(/( )|(-)/g, '_')
    .replace(/[^a-z0-9_]/g, '')
    .replace(/^[0-9]/, (match) => `_${match}`);

export const id2js = (id: Id): string =>
  id.replace(/-/g, '_').replace(/^[0-9]/, (match) => `_${match}`);

export const js2id = (js: string): Id =>
  js.replace(/^_/, '').replace(/_/g, '-');
