// @flow
import React from 'react';
import styled from 'styled-components';
import type { Id } from '../utils/types';
import { getBreadCrumbs } from '../utils/front-matter-helpers';
import Link from './link';
import mapWithDelimiter from '../utils/map-with-delimiter';

const Container = styled.p`
  display: flex;
  flex-wrap: wrap;
  font-size: 80%;
`;

// $FlowFixMe styled-components-v4
const Separator = styled.span.attrs({
  children: '»',
})`
  padding: 0 8px;
`;

export default class BreadCrumbs extends React.Component<{ id: Id }> {
  render() {
    const frontMatters = getBreadCrumbs(this.props.id);

    if (frontMatters.length < 2) {
      return null;
    }

    const links = frontMatters.map((front, index) => (
      <span key={front.id}>
        {index === frontMatters.length - 1 ? (
          <span>{front.title}</span>
        ) : (
          <Link to={front.route}>{front.title}</Link>
        )}
      </span>
    ));

    return <Container>{mapWithDelimiter(links, <Separator />)}</Container>;
  }
}
