// @flow
import dateFormat from 'dateformat';

const getDateString = (date?: Array<number>): string => {
  if (!date || date.length !== 3) {
    return '';
  }
  const year = date[0];
  const month = date[1];
  const day = date[2];

  if (
    !year ||
    year < 1950 ||
    !month ||
    month < 1 ||
    month > 12 ||
    !day ||
    day < 1 ||
    day > 31
  ) {
    return '';
  }

  return dateFormat(new Date(year, month - 1, day), 'mmmm d, yyyy');
};

export default getDateString;
