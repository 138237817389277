// @flow
import frontMatterMap from '../data/front-matter';
import type { FrontMatter, Id } from './types';

//region getPreviousSibling and getNextSibling

const siblingList: Array<FrontMatter> = [];
const siblingIndexMap: { [id: Id]: number } = {};

const _insertIntoSiblingList = (frontMatter: FrontMatter): void => {
  siblingList.push(frontMatter);
  frontMatter.children.forEach((child) => {
    _insertIntoSiblingList(frontMatterMap[child]);
  });
};

// Init sibling list
_insertIntoSiblingList(frontMatterMap['root']);

// Init sibling index map
siblingList.forEach((front, index) => {
  siblingIndexMap[front.id] = index;
});

export const getPreviousSibling = (
  frontMatter: FrontMatter | Id
): ?FrontMatter => {
  const id = typeof frontMatter === 'string' ? frontMatter : frontMatter.id;
  const index = siblingIndexMap[id];
  if (index !== undefined) {
    return siblingList[index - 1];
  }
  return undefined;
};

export const getNextSibling = (frontMatter: FrontMatter | Id): ?FrontMatter => {
  const id = typeof frontMatter === 'string' ? frontMatter : frontMatter.id;
  const index = siblingIndexMap[id];
  if (index !== undefined) {
    return siblingList[index + 1];
  }
  return undefined;
};

//endregion getPreviousSibling and getNextSibling

//region getBreadCrumbs

export const getBreadCrumbs = (id: Id): Array<FrontMatter> => {
  const array: Array<FrontMatter> = [];
  const front = frontMatterMap[id];
  if (front) {
    _getBreadCrumbs(array, front);
  }
  return array.reverse();
};

const _getBreadCrumbs = (
  array: Array<FrontMatter>,
  current: FrontMatter
): void => {
  array.push(current);
  if (current.parent) {
    const parent = frontMatterMap[current.parent];
    _getBreadCrumbs(array, parent);
  }
};

//endregion getBreadCrumbs
