// @flow
import React, { type Node } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { MdMenu } from 'react-icons/md';
import getDateString from '../utils/date';
import type { FrontMatter } from '../utils/types';
import Drawer from './drawer';
import BreadCrumbs from './bread-crumbs';
import PreviousNextPages from './previous-next-pages';
import { large_screen } from '../utils/styles';
import Helmet from 'react-helmet';
import { threshold } from '../utils/styles';
import OpenDrawer from './open-drawer';
import TableOfContents, { injectTocIds } from './table-of-contents';
import ScrollUpButton from './scroll-up-button';

const GlobalStyle = createGlobalStyle`
  body {
    cursor: default;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const MenuIcon = styled(MdMenu).attrs({
  size: 24,
  color: 'black',
})``;

const MenuButton = styled.a.attrs({
  children: <MenuIcon />,
})`
  cursor: pointer;
  position: fixed;
  z-index: 1;
  align-self: flex-start;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 16px;
  border-radius: 28px;
  line-height: 12px;
  transition: background-color 300ms;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  &:active {
    background-color: rgba(0, 0, 0, 0.08);
  }

  ${large_screen} {
    margin: 16px;
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0 auto;
  padding: 0 16px;
  max-width: 50rem;
  width: 100%;
`;

const Date = styled.p`
  font-style: italic;
`;

type Props = {
  frontMatter: FrontMatter,
  children: Node,
  hideHeader: ?boolean,
  hideFooter: ?boolean,
  hideHorizontalRules?: boolean,
  toc: boolean,
  scrollUpButton: boolean,
};

type State = {
  showDrawer: boolean,
  width: number,
};

export default class Layout extends React.Component<Props, State> {
  static defaultProps = {
    hideHeader: false,
    hideFooter: false,
    hideHorizontalRules: false,
    toc: false,
    scrollUpButton: false,
  };

  state = {
    showDrawer: false,
    width: 0,
  };

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  render() {
    const {
      frontMatter,
      children,
      hideHeader,
      hideFooter,
      hideHorizontalRules,
    } = this.props;
    const { showDrawer, width } = this.state;

    const date = getDateString(frontMatter.date);

    return (
      <div>
        <GlobalStyle />
        <Helmet
          defaultTitle={`Canyon Companion`}
          titleTemplate={`%s | Canyon Companion`}
        >
          <title>{frontMatter.title}</title>
          <meta
            name="og:title"
            content={
              frontMatter.title === 'Home'
                ? 'Canyon Companion'
                : frontMatter.title
            }
          />
          <meta name="og:description" content={frontMatter.preview} />
          <meta name="og:type" content="website" />
          <meta name="og:site_name" content="Canyon Companion" />
          {frontMatter.image && (
            <meta name="og:image" content={frontMatter.image} />
          )}
          {frontMatter.image && frontMatter.image_alt && (
            <meta property="og:image:alt" content={frontMatter.image_alt} />
          )}
          {frontMatter.image && (
            <meta property="og:image:type" content="image/jpeg" />
          )}
          <meta
            name="og:url"
            content={`https://canyoncompanion.com${frontMatter.route}`}
          />
          <link
            rel="canonical"
            href={`https://canyoncompanion.com${frontMatter.route}`}
          />
          <html lang="en" />
        </Helmet>
        <MenuContainer>
          {frontMatter.route !== '/' && (
            <>
              {width < threshold ? (
                <>
                  <MenuButton onClick={this.toggleDrawer} />
                  <Drawer
                    open={showDrawer}
                    onChange={this.setDrawer}
                    route={frontMatter.route}
                  />
                </>
              ) : (
                <OpenDrawer route={frontMatter.route} />
              )}
            </>
          )}
          <PageContainer>
            {!hideHeader && (
              <>
                <h1>{frontMatter.title}</h1>
                {date && <Date>{date}</Date>}
                <BreadCrumbs id={frontMatter.id} />
                {!hideHorizontalRules && <hr />}
              </>
            )}
            {this.props.scrollUpButton && <ScrollUpButton />}
            {this.props.toc && <TableOfContents>{children}</TableOfContents>}
            {this.props.toc ? injectTocIds(children) : children}
            {!hideFooter && (
              <>
                {!hideHorizontalRules && <hr />}
                <PreviousNextPages id={frontMatter.id} />
              </>
            )}
          </PageContainer>
        </MenuContainer>
      </div>
    );
  }

  toggleDrawer = () => {
    return this.setDrawer(!this.state.showDrawer);
  };

  setDrawer = (open: boolean) => {
    this.setState({ showDrawer: open });
  };
}
